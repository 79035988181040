import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KhushuPattern from '../../assets/img/Khushu-Pattern.png';




const useStyles = makeStyles((theme) => ({
    FD_banner_img: {
        background: `#8ba4b9 url(${KhushuPattern})`,
        backgroundSize: "cover",
        opacity: 1,
        backgroundPosition: "center",
        paddingTop: "60px",
        paddingBottom: "60px",
    },
    FD_inner_banner: {
        padding: '24px'
    },
    FD_Hometitle: {
        fontWeight: "800",
        fontSize: "42px",
        margin: "10px 0",
        letterSpacing: "0.48px",
        color: "white",
        maxWidth: '500px'
    },
    FD_homeBanner: {
        textAlign: "center",
        color: "#fff",
        padding: "0 140px",
    },
    FD_line : {
        background: "#dae2e9",
        height: "3px",
        maxWidth: "100px",
        borderRadius: "100px",
        margin: "15px auto",
    },
    FD_verse: {
        color: "white",
        fontSize: "16px",
        margin: "16px 0px"
    },
    }));


const Banner = (props) =>  {
    const {title} = props;

    const classes = useStyles();
    return (
    <Grid container  className={classes.FD_banner_img} >
        <Grid container className={classes.FD_footerInner} >
            <Grid className={classes.FD_homeBanner} container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                
                <Grid container xs={12} direction="row" justify="center" >
                    <Typography className={classes.FD_Hometitle}  component="h2">
                        The Reward of Goodnes is Nothing but Goodness
                        <Divider className={classes.FD_line} variant="fullWidth" />
                        <Typography className={classes.FD_verse}>
                            AL-QURAN 55:61
                        </Typography>
                    </Typography>
                    

                    
                </Grid>

            </Grid>
        </Grid>
      

    </Grid>
    );
}

export default Banner;