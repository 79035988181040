import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { Donation } from '../screens';
import Index from '../screens/pages/index';


function Navigations () {
  return (
    <Router>
      <Route exact path='/:id' component={Donation} />
      <Route exact path='/:id/:invitedDonor' component={Donation} />
      <Route exact path='/privacy-policy' component={Index} />
      <Route exact path='/terms-policies' component={Index } />
      <Route exact path='/about-us' component={Index } />
    </Router>

  )
}

export default Navigations
