import React, { Component } from 'react'
import { connect } from 'react-redux'
import DoneIcon from '@material-ui/icons/Done'
import { updateSteps } from '../../store/Steps/actions.js'
import './style.css'

export class Stepper extends Component {
  constructor (props) {
    super(props)

    this.state = {
      step: 0
    }
    this.nextstep = this.nextstep.bind(this)
  }

  nextstep () {
    const { step } = this.state
    this.setState({
      step: step + 1
    })
  }

  componentWillReceiveProps () {
    this.nextstep()
  }

  render () {
    const { step } = this.props.steps
    return (
      <>
        <div className='stepwizard'>
          <div className='stepwizard-row setup-panel'>
            <div
              className={`${
                step > 0
                  ? 'stepwizard-step step_done'
                  : 'stepwizard-step step_current'
              }`}
            >
              <a href='javascript:void(0)' type='button' className=''>
                {step >= 1 ? <span><DoneIcon className='icon' /></span> : <span>1</span>}
              </a>
              <p>Donation</p>
            </div>
            <div
              className={`${
                step > 1
                  ? 'stepwizard-step step_done'
                  : step === 1
                  ? 'stepwizard-step step_current'
                  : 'stepwizard-step'
              }`}
            >
              <a
                href='javascript:void(0)'
                type='button'
                className=''
                disabled='disabled'
              >
                {step >= 2 ? <span><DoneIcon className='icon' /></span> : <span>2</span>}
              </a>
              <p>Donor</p>
            </div>
            <div
              className={`${
                step > 2
                  ? 'stepwizard-step step_done'
                  : step === 2
                  ? 'stepwizard-step step_current'
                  : 'stepwizard-step'
              }`}
            >
              <a
                href='javascript:void(0)'
                type='button'
                className=''
                disabled='disabled'
              >
                {step >= 3 ? <span><DoneIcon className='icon' /></span> : <span>3</span>}
              </a>
              <p>Payment</p>
            </div>
            <div
              className={`${
                step > 3
                  ? 'stepwizard-step step_done'
                  : step === 3
                  ? 'stepwizard-step step_current'
                  : 'stepwizard-step'
              }`}
            >
              <a
                href='javascript:void(0)'
                type='button'
                className=''
                disabled='disabled'
              >
                {step >= 4 ? <span><DoneIcon className='icon' /></span> : <span>4</span>}
              </a>
              <p>RECEIPT</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps (state) {
  return {
    steps: state.steps
  }
}

function mapDispatchToProps (dispatch) {
  return {
    storeSteps: (payload) => dispatch(updateSteps(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stepper)
