const INITIAL_STATE = {
  amount: '',
  donationType: 'Monthly',
  fee: true,
  category: '',
  categoryId: '',
  note:'',
  orgId : ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_AMOUNT': {
      return { ...state, amount: action.payload }
    }
    case 'SET_DONATIONTYPE': {
      return { ...state, donationType: action.payload }
    }
    case 'SET_FEE': {
      return { ...state, fee: action.payload }
    }
    case 'SET_CATEGORY': {
      return { ...state, category: action.payload, categoryId: action.id }
    }
    case 'SET_ORGANIZATION_ID': {
      return { ...state, orgId: action.payload }
    }
    case 'SET_NOTE': {
      return { ...state, note: action.payload}
    }


    default:
      return state
  }
}