import React, { Component } from 'react'
import logo from '../../assets/img/emb_masjid_logo.png'
import './style.css'
import { getCompany } from './api'
import { connect } from 'react-redux'
class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      company: {},
      loading: true,
      ShowHeader: true
    }
  }

  componentDidMount () {
    // this.getCompanyLogo()
    const params = new URLSearchParams(window.location.search)
    const paramVal = params.get('show')
    if(paramVal === false || paramVal === 'false'){
      console.log('paramVal',paramVal)
      this.setState({ShowHeader : paramVal })
    }
  }
  render() {
    const { ShowHeader } = this.state
    const { org, orgData } = this.props
    const header = org?.org; 
    return (
      <>
        {ShowHeader && header ?
          <div className='embed_header'>
            <div className='container'>
            <div className='embd_logo'>
                {
                  orgData?.enabledIntegrations?.deenfund?.embedForm?.[0]?.formImage?.src ?
                  <a href='javascript:void(0)'><img src={orgData?.enabledIntegrations?.deenfund?.embedForm?.[0]?.formImage?.src} /></a> :
                  orgData?.orgLogo?.logoThumbnail && <a href='javascript:void(0)'><img src={orgData?.orgLogo?.logoThumbnail} /></a>
                }
              </div>
              <div className='embd_hdr_right'>
                {header?.embedForm?.[0]?.formHeaderButton?.link && <a className='outline_btn' target="_blank"  href={header?.embedForm?.[0]?.formHeaderButton?.link}>HOMEPAGE</a>}
              </div>
            </div>
          </div> : null}
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    org: state.org,
    orgData: state.org.orgData
  }
}

export default connect(mapStateToProps, null)(Header)
