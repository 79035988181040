import React from 'react';
import Banner from '../common/banner';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    FD_pagePart: {
        background: "#05CE78",
        textAlign: "center",
        height: "52px"
    },
    FD_padding_zero: {
        padding: "0 !important"
    },
    tabsStyles: {
        width:"auto",
        color: "white",
        fontWeight: 500
    },
    singleTab: {
      height: "28px",
      marginTop: "0px",
      minWidth: "auto",
      padding: "0px 24px 0px 24px",
      opacity: 'inherit'
    },
    bigIndicator: {
      height: 5,
      backgroundColor: "white"
    },
    activeTextColor: {
      color: "white",
      fontWeight: "bold"
    },
    FD_bodyPart: {
        maxWidth: "1140px",
        margin: "0 auto",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    inner_Body: {
        padding: "24px"
    },
    FD_bodyTitle:{
        color: "#294C5C",
        fontSize: "28px",
        fontWeight: 600,
        margin: "5px 0",
    },
    FD_updatedTime: {
        margin: "5px 0",
        color: "#294C5C",
        opacity: "0.8",
        fontSize: "14px"
    },
    FD_bodysubTitle: {
        color: "#294C5C",
        fontSize: "26px",
        fontWeight: 600,
        margin: "5px 0"
    },
    FD_bodyText: {
        color: "#294C5C",
        fontSize: "14px",
        margin: "14px 0",
        lineHeight: 2,
        letterSpacing: "0.18px"
    },
    FD_linkColor: {
        color: "#05CE78",
        textDecoration: "none"
    }

    }));




const TermsPolicy = () =>  {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <Banner title="Terms and Policies"/>

            <Grid container  className={classes.FD_pagePart} >
                <Grid container className={classes.FD_padding_zero} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
    
                        <Grid container xs={12} justify="center" >
                        <Tabs
                        className={classes.tabsStyles}
                            value={value}
                            classes={{indicator: classes.bigIndicator}}
                            onChange={handleChange}
                            aria-label="tab"
                            
                            
                        >
                            <Tab className={classes.singleTab+" "+(value === 0 ? classes.activeTextColor : '' )} label="TERMS OF SERVICE" />
                            <Tab className={classes.singleTab+" "+(value === 1 ? classes.activeTextColor : '' )} label="PRIVACY POLICY" />
                        </Tabs>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid container  className={classes.FD_bodyPart} >

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                TERMS OF SERVICE
                            </Typography>
                            <Typography className={classes.FD_updatedTime} variant="body1" component="p">
                                Last updated: Apr 17, 2020
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENFUND (“Effective Date”). This Agreement governs the relationship between DEENFUND, INC. a New York Corporation, 81 Prospect St. Brooklyn, NY 11201 (“DEENFUND,” the “Company,” “we,” “our,” or “us”), and you the account holder, user, or licensee (“You” or “User) (collectively, the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, or through our website.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENFUND (“Effective Date”). This Agreement governs the relationship between DEENFUND, INC. a New York Corporation, 81 Prospect St. Brooklyn, NY 11201 (“DEENFUND,” the “Company,” “we,” “our,” or “us”), and you the account holder, user, or licensee (“You” or “User) (collectively, the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, or through our website.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                Please read the Agreement carefully before you start to use the Website. By using the Website, or by clicking to “accept” or “agree” to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy, incorporated herein by reference. You also acknowledge and warrant, if applicable, that you have the authority to enter into this agreement on behalf of, and bind, the entity for which you will be using the Platform as a registered user. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                If you do not want to agree to these Terms of Use or the <Link to="#" color={classes.FD_linkColor}>Privacy Policy</Link>, you must not access or use the Website.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                1 - Grant of Rights
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="h1" component="h1">
                                <b>Platform:</b> Subject to the terms and conditions set forth herein, we hereby grant you a limited, world-wide, non-exclusive, fees-based, nontransferable right and license, under our Intellectual Property Rights
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                (i) Use, in the form in which such Platform is delivered by the DEENFUND only
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                (ii) Utilize such Platform only for the purpose of receiving donations.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>Limited Rights of License:</b> Your obtainment and/or use of the Platform does not grant you any rights of ownership in the Platform, as all rights granted are merely those of a licensee under the terms of this Agreement, with those rights granted only for such time as:
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                a. You conform to the terms and conditions of this Agreement
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                b. Until the termination of this Agreement.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>No Other Rights:</b> Except for the limited rights expressly granted under this Agreement, neither this Agreement nor Your exercise of rights granted convey any other rights or license to the Platform, Confidential Information, Documentation, and Marks whether by implication. We retain all rights, title, and interest in and to the Platform, Confidential Information, Documentation, and Marks (as defined below), including without limitation, all worldwide right, title and interest in and to all patents and all filed, pending applications for patents, including any reissue, reexamination, divisional, continuation or continuation-in-part patent applications now or hereafter filed (“Patent Rights”);
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                a. All trade secrets, and all trade secret rights and equivalent rights arising
                            </Typography>
                           <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                b. All works of authorship, including all registered and unregistered copyrights
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                c. All proprietary indicia, trademarks, trade names, symbols, logos and/or brand names (“Marks”), in each case
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                d. As the same may arise or exist under common law, state law, federal law and laws of foreign countries (collectively “Intellectual Property Rights”).
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                2 - Payments
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Transaction Fee.</b> The fees owed with respect to the rights and licenses granted hereunder shall be no more than 4 percent (%4.000) per unique credit card donation. A bill will be created once a month with those aggregate fees. These fees do not include Stripe’s processing fees, which will also be deducted immediately at the time of the transaction processing.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>Taxes:</b> The fees charged do not include any foreign, federal, state or local sales, use or other similar taxes, however designated, levied against the sale, licensing, delivery or use of the Platform. You agree to pay, or reimburse, DEENFUND for all such taxes imposed, provided, however, that you shall not be liable for any taxes based on DEENFUND net income. You agree to promptly notify us of any amendment or revocation of such certificate, and will hold DEENFUND harmless from and against any taxes or other monies resulting from the failure to hold a certificate recognized as valid by the appropriate tax authority.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>Stripe:</b> Stripe is a secure, modern payment processor trusted by major tech companies such as Lyft, Shopify, and Kickstarter. It gives you the ability to accept cards, low fee ACH bank donations, and Apple Pay. Best of all, the signup process only takes 5 minutes and there are no monthly charges. DEENFUND is a state of the art donation and fundraising platform with native integration with Stripe. It helps you easily to accept one-time and recurring donations from donors. With DEENFUND, you won't need to hire a developer to integrate with the Stripe API. PCI compliance and card saving security are also taken care of for you. Your donation forms can be localized in 8 languages and 20 major currencies. The project is available to any organization/any donor from anywhere in the world. DEENFUND gives your donors the ability to update expired cards and edit their recurring plans at any time. DEENFUND is a powerful stripe donation plugin that is super simple to setup and attracts more recurring donors. DEENFUND is used by nonprofits, churches, universities, politicians, and medical research organizations. Contact us at <Link to="#" className={classes.FD_linkColor}>privacy@deenfund.com</Link> in case of any difficulties or technical issues.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                3 - Your Additional Obligations
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Performance Obligations.</b> You shall not alter, re-label or change the Platform as provided by DEENFUND, and as may be amended at any time at our sole discretion, without our prior written consent.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>Books and Records.</b> You agree to keep and maintain accurate books and records as reasonably necessary for verification of transaction payments pursuant to this Agreement.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                            <b>Terms and Conditions of Card Processor (Stripe). </b>You agree at all times to conform to The terms and conditions of credit card processing as defined by <Link to="#" className={classes.FD_linkColor}>Stripe.com</Link>, which terms and conditions may be amended from time-to-time at the sole discretion of Stripe.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                4 - Disclaimer of Warranties. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                The Website is provided “as is”. DEENFUND and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither DEENFUND nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Indemnification.</b>&nbsp;You agree to indemnify and hold harmless DEENFUND, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                5 - Terms and Termination 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Termination.</b>&nbsp;DEENFUND may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your TopNonprofits.com account (if you have one), you may simply discontinue using the Website. DEENFUND can terminate the Website immediately as part of a general shut down of our service. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                Without limiting the foregoing, you agree to not use the Services to establish or contribute to any Organizations with the implicit or express purpose relating to any of the following:
                            </Typography>
                            <li className={classes.FD_bodyText}>Any activity that violates any law or governmental regulation;</li>
                            <li className={classes.FD_bodyText}>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
                            <li className={classes.FD_bodyText}>The promotion of hate, violence, harassment, discrimination, terrorism, or intolerance of any kind, including by race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender identity, or serious disabilities or diseases;</li>
                            <li className={classes.FD_bodyText}>Illegal drugs, narcotics, steroids, controlled substances or other products that present a risk to consumer safety or any related paraphernalia;</li>
                            <li className={classes.FD_bodyText}>Ransom, human trafficking or exploitation;</li>
                            <li className={classes.FD_bodyText}>Knives, explosives, ammunition, firearms, or other weaponry or accessories;</li>
                            <li className={classes.FD_bodyText}>Gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a lottery) or sweepstakes;</li>
                            <li className={classes.FD_bodyText}>Offensive, graphic, perverse or sensitive content, including pornography or other sexual content;</li>
                            <li className={classes.FD_bodyText}>Annuities, investments, equity or lottery contracts, lay-away systems, off-shore banking or similar transactions, money service businesses (including currency exchanges, check cashing or the like), debt collection or crypto-currencies;</li>
                            <li className={classes.FD_bodyText}>Offering monetary rewards, including gift cards, without declaring fair market value (FMV) of goods exchanged;</li>
                            <li className={classes.FD_bodyText}>Transactions for the sale of items before the seller has control or possession of the item;</li>
                            <li className={classes.FD_bodyText}>Collection of payments on behalf of merchants by payment processors or otherwise;</li>
                            <li className={classes.FD_bodyText}>Credit repair or debt settlement services.</li>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Termination for Insolvency.</b> This Agreement shall terminate, without notice:
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                (i) Upon the institution by or against either Party of bankruptcy proceedings, which proceedings are not dismissed within ninety (90) days of their commencement, or
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                (ii) Upon either Party’s making an assignment for the benefit of creditors, or
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                (iii) Upon either Party’s dissolution.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Limitation of Liability.</b> In the event of termination of this Agreement by either Party in accordance with any of the provisions of this Agreement, neither Party shall be liable to the other, because of such termination, for compensation, reimbursement or damages on account of the loss of prospective profits or anticipated sales, or on account of expenditures, inventory, investments, leases or commitments in connection with the business or goodwill of either Party. Termination shall not, however, relieve either Party of obligations incurred prior to the termination.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                6 - Intellectual Property Notices and Confidentiality
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Intellectual Property Notices.</b> You agree not to remove and to retain all proprietary Marks, legends and IP notices that appear on or display in connection with the Platform, documentation, and Confidential Information delivered to you, and all whole or partial copies thereof. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any DEENFUND or third-party trademarks.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Confidentiality</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Confidential Information.</b> For purposes of this Agreement, a Party’s Confidential Information shall mean:
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                a. All information considered by one Party to be confidential and which is clearly marked as “confidential” prior to disclosure to the other Party, or if disclosed orally or visually disclosed, shall be identified as the confidential information of the disclosing Party at the time of disclosure and then summarized in writing and provided to the recipient within thirty (30) days of such oral or visual disclosure
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                b. All information concerning or related to the Platform, including but not limited to the logic, designs, source code, product specifications, inventions, research, improvement, manufacture and sale of the Platform (including sales, costs, profits, pricing methods, organizations, business and product plans)
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                c. Any other information which a reasonable business person would consider to be confidential information of the other Party. Confidential Information shall not include information which: (i) is or becomes public knowledge without any action by, or involvement of, the receiving Party; (ii) is disclosed by one Party with the prior written approval of the other Party, (iii) is received by the receiving Party from a third party without a confidentiality obligation or duty of nondisclosure; or (iv) is disclosed pursuant to any judicial or governmental order, provided that to the extent consistent with such order the disclosing Party gives the other Party sufficient prior notice to contest such order.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Non-Use and Non-Disclosure.</b> The Parties to this Agreement agree to observe complete confidentiality with respect to the Confidential Information, and to make all reasonable efforts not to disclose, or permit any third party or entity access to, the Confidential Information (or any portion thereof) without the prior written permission of the other Party (except such disclosure or access which is required to perform any obligations under this Agreement and to ensure that any employees, or any third parties who obtain access to the Confidential Information, are advised of the confidential and proprietary nature thereof and are prohibited from copying, utilizing or otherwise revealing the Confidential Information.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Source Code.</b> You agree not to attempt, directly or indirectly, to decompile, disassemble, reverse engineer or use any other similar process with respect to the code, logic or information embodied by the Platform.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Use of your donation</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND does not warrant that your donations will be used for any particular purpose and shall not be responsible for any dissatisfaction you may have regarding the recipient Cause's use of any donation you may make through the Website or its associated services or websites powered by us or for any misuse or non-use of such donations by the recipient Cause. After donations are made, all further dealings are solely between the donor and such recipient organization. Please note that recipient organization (Masjids/Not-for-profit Muslim organizations) reserve the right to use your donation for their general purposes. They will use your donation for any purpose in accordance with their own rules. DEENFUND cannot guarantee that funds will be earmarked for a particular appeal. If you want your donation to be used for a specific purpose or for a particular appeal you should contact the organization and make your donation directly to them.
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                8 - General Provisions
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Child Protection</b>
                            </Typography>
                           
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Use.</b> During the Term, you shall have the right to use DEENFUND Marks to advertise and identify that your website donation program is administered with the Platform. You shall use such Marks in accordance with DEENFUND’s usage in the Platform, and shall not modify or delete such Marks as set forth in the Platform.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Applicable Law.</b> Not with standing anything in this Agreement to the contrary, the laws of the State of New York shall govern the performance and construction of this Agreement and the interpretation of the Parties’ rights and duties without reference to its conflict of laws principles.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Entire Agreement.</b> This Agreement sets forth the entire agreement and understanding of the Parties relating to the subject matter herein and merges all prior discussions between them. No modification of or amendment to this Agreement, or any waiver of any rights under this Agreement, shall be effective unless in writing signed by both Parties.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Notices.</b> Any notice required or permitted to be given under this Agreement shall be deemed given when delivered (i) by hand, (ii) by registered or certified mail, postage prepaid, return receipt requested, the address of the other Party first set forth above, or to such other address as a Party may designate by written notice to the other Party no less than thirty (30) days prior to change of such address, (iii) by overnight courier, or (iv) by fax with confirming letter mailed under the conditions described in (ii) above.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Force Majeure</b> (unforeseeable circumstances that prevent someone from fulfilling a contract) Except for the obligation to make payments, non-performance of either Party shall be excused to the extent the performance is rendered impossible by strike, fire, flood, terrorism, governmental acts or orders or restrictions, failure of suppliers or any other reason where failure to perform is beyond the reasonable control of, and is not caused by, the negligence of the non-performing Party.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Legal Expenses.</b> The prevailing Party in any legal action brought by one Party against the other and arising out of this Agreement shall be entitled, in addition to any other rights and remedies it may have, to reimbursement for its expenses, including court costs and reasonable attorneys’ fees.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Specific Performance; Injunctive Relief.</b> Your breach of any obligation under Section6 of this Agreement or regarding the use, duplication, modification, transfer or confidentiality of any Confidential Information, documentation or otherwise shall entitle DEENFUND to injunctive, specific performance or other equitable relief, all without need of bond or undertaking of any nature, Licensee hereby specifically acknowledges that DEENFUND’s remedies at law under such circumstances would be inadequate.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Authorizations.</b> During the term of is Agreement, you shall, at your own expense, make, obtain, and maintain in force at all times during the Term of this Agreement, all filings, registrations, reports, licenses, permits and authorization from any private or governmental agency which are necessary for you to exercise your rights and perform your obligations hereunder (“Authorizations”). DEENFUND shall provide you, at your expense, with such assistance as you may reasonably request in making or obtaining any such Authorizations.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Approvals and Enforceability.</b> You hereby represent and warrant that no Authorization or other consent, approval or authorization of or designation, declaration or filing with any governmental authority is required in connection with the valid execution, delivery and performance of this Agreement. Additionally, you further represent that you have the legal authority to bind the corporation or entity on whose behalf you are obtaining the Platform.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Copyright</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                All content on the Website and its associated services is owned by DEENFUND, our member charities, DEENFUND Projects or other original providers, and is protected by the applicable intellectual property and proprietary rights and laws. You may copy content for your own personal, non-commercial use provided you do not alter it or remove any copyright, trademark or other proprietary notice. No other use of the Website's and its associated services' content is permitted without the express prior permission of DEENFUND, and, where applicable, the copyright holder. For inquiries and permission requests please contact us.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Changes to these Terms</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. If you are not willing to agree to the changes, your sole recourse is to immediately discontinue the use of DEENFUND platform.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
            
    );
}

export default TermsPolicy;