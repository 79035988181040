import React from 'react';
import Banner from '../common/about_banner';
import { Grid, Divider, Button , FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Icon1 from '../../assets/img/icon_1.png';
import Icon2 from '../../assets/img/icon_2.png';
import Icon3 from '../../assets/img/icon_3.png';
import Icon4 from '../../assets/img/icon_4.png';
import Icon5 from '../../assets/img/icon_5.png';
import Icon6 from '../../assets/img/icon_6.png';
import PointingArrow from '../../assets/img/pointing_arrow.png';
import featureDownArrow from '../../assets/img/pointing_arrow_bottom.png';
import amount from '../../assets/img/ammount_img.png';
import org from '../../assets/img/org_img.png';
import donation from '../../assets/img/donation_img.png';
import others from '../../assets/img/others_img.png';
import percentage_bg from '../../assets/img/percentage_bg.png';





const useStyles = makeStyles((theme) => ({
    FD_pagePart: {
        background: "#05CE78",
        textAlign: "center",
        height: "52px"
    },
    FD_padding_zero: {
        padding: "0 !important"
    },
    tabsStyles: {
        width:"auto",
        color: "white",
        fontWeight: 500
    },
    singleTab: {
      height: "28px",
      marginTop: "0px",
      minWidth: "auto",
      padding: "0px 24px 0px 24px",
      opacity: 'inherit'
    },
    bigIndicator: {
      height: 5,
      backgroundColor: "white"
    },
    activeTextColor: {
      color: "white",
      fontWeight: "bold"
    },
    inner_Body: {
        padding: "24px"
    },
    FD_aboutTitle:{
        fontWeight: "bold",
        fontSize: "28px",
        textAlign: "center",
        color: "#294C5C",
        margin: "15px 0",
    },
    FD_linkColor: {
        color: "#05CE78",
        textDecoration: "none"
    },
    FD_line : {
        background: "#dae2e9",
        height: "3px",
        maxWidth: "100px",
        borderRadius: "100px",
        margin: "15px auto",
    },
    FD_aboutPara: {
        color: "#4C5E67",
        opacity: 0.8,
        textAlign: "center",
        fontSize: "18px",
        lineHeight: 1.5,
        padding: "0 20px",
    },
    FD_aboutSection: {
        background: "#f9f9f9",
        padding: "60px 0",
    },
    FD_aboutTextSection: {
        maxWidth: "940px",
        margin: "0 auto",
        padding: "0 20px",
    },
    FD_aboutText:  {
        fontSize: "16px",
        color: "#4C5E67",
        opacity: 0.8,
        letterSpacing: "0.18px",
        lineHeight: 1.5,
        margin: "18px 0px"
    },
    FD_aboutLink: {
        fontWeight: 400,
    },
    FD_featureSection: {
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "50px 0"
    },
    featuredGird: {
        padding: '24px'
    },
    FD_featureImg: {
        width: "65px",
    },
    FD_FeaturesPoint: {
        textAlign: "center"
    },
    FD_PointTitle: {
        color: "#294C5C",
        fontWeight: "bold",
        fontSize: "18px",
        margin: "18px 0"
    },
    FD_PointText: {
        color: "#4C5E67",
        opacity: 0.8,
        fontSize: "15px",
        lineHeight: 1.5
    },
    FD_DonorSection: {
        background: "#f9f9f9",
        padding: "50px 0"
    },
    FD_DonorMenuSection: {
        maxWidth: "1140px",
        margin: "0 auto",
    },
    FD_DonorPart: {
        background: "#fff",
        borderRadius: "12px",
        margin: '0px 12px',
        padding: "15px !important",
        boxShadow: "0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15)",
        textAlign: "center",
        height: "400px",
        maxWidth: '22%'
    },
    FD_featureArrowImg: {
        position: "absolute",
        marginLeft: "188px",
        marginTop: "-15px",
    },
    FD_secondDonor: {
        marginTop: "52px",
        height: "420px !important",
    },
    FD_featureDownArrowImg: {
        position: "absolute",
        marginLeft: "112px",
        marginTop: "12px",
    },
    FD_signUpNow: {
        background: "#05CE78 !important",
        color: "#fff !important",
        width: "160px",
        height: "45px",
        display: "block",
        margin: "45px auto 0",
    },
    FD_adSection: {
        background: "#B8B8B8",
        opacity: 0.45,
        paddingTop: "70px",
        paddingBottom: "70px",
    },
    FD_adTitle: {
        fontSize: "64px",
        textAlign: "center",
        fontWeight: "bold",
    },
    FD_percentage: {
        margin: "28px auto",
        maxWidth: "1140px",
        textAlign: "center",
    },
    FD_percentageTitle: {
    color: "#294C5C",
    fontSize: "28px",
    },
    sws_work: {
        padding: "30px",
        boxShadow: "0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15)",
        borderRadius: "12px",
        width: "80%",
        position: "relative",
        margin: "0 auto",
    },
    percentage: {
        background: `url(${percentage_bg})`,
        width: "289px",
        height: "346px",
        top: "10px",
        backgroundRepeat: "no-repeat",
        paddingTop: "90px",
        float: "left",
    },
    prc_col: {
        marginLeft: "-13px",
    },
    percentage_span: {
        fontSize: "110px",
        fontStyle: "italic",
        color: "#fff",
        fontWeight: "bold",
        position: "relative",
    },
    percentage_span_label: {
        fontSize: "50px",
        position: "absolute",
        top: "26px",
        marginLeft: "10px",
        fontWeight: 900,
        color: "#fff"
    }
    }));




const AboutUs = () =>  {
    const [value, setValue] = React.useState(4);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <Banner />

            <Grid container  className={classes.FD_pagePart} >
                <Grid container className={classes.FD_padding_zero} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
    
                        <Grid container xs={12} justify="center" >
                        <Tabs
                        className={classes.tabsStyles}
                            value={value}
                            classes={{indicator: classes.bigIndicator}}
                            onChange={handleChange}
                            aria-label="tab"
                            
                            
                        >
                            <Tab className={classes.singleTab+" "+(value === 0 ? classes.activeTextColor : '' )} label="ABOUT US" />
                            <Tab className={classes.singleTab+" "+(value === 1 ? classes.activeTextColor : '' )} label="FEATURES" />
                            <Tab className={classes.singleTab+" "+(value === 2 ? classes.activeTextColor : '' )} label="HOW IT WORKS" />
                            <Tab className={classes.singleTab+" "+(value === 3 ? classes.activeTextColor : '' )} label="PRICING" />
                            <Tab className={classes.singleTab+" "+(value === 4 ? classes.activeTextColor : '' )} label="FAQS" />
                        </Tabs>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid container  className={classes.FD_aboutSection} >
                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" className={classes.FD_aboutTextSection} >
                                <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                                    ABOUT DEENFUND
                                    <Divider className={classes.FD_line} />
                                </Typography>
                                <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                                    We are a global platform connecting awesome donors
                                </Typography>
                                <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                                    with amazing organizations with great causes.
                                </Typography>
                                <Typography className={classes.FD_aboutText} variant="body1" component="p" >
                                    Muslims &amp; organizations collectively around the globe has pioneered and contributed immensely to the development of human civilization. Fatima and Miriam al-Firhi, prioneered the education system and built world’s first university, Al-Qarawihhin in Fex, Morocco. Our remarkable contributions to philosophy, health care, science, chemistry, finance, technology, physics and many other fields have led to Noble Prizes, highest recognitions, made it’s mark in history, and most of all shaped our human civilization.
                                </Typography>
                                <Typography className={classes.FD_aboutText} variant="body1" component="p" >
                                    Muslims & organizations collectively around the globe has pioneered and contributed immensely to the development of human civilization. Fatima and Miriam al-Firhi, prioneered the education system and built world’s first university, Al-Qarawihhin in Fex, Morocco. Our remarkable contributions to philosophy, health care, science, chemistry, finance, technology, physics and many other fields have led to Noble Prizes, highest recognitions, made it’s mark in history, and most of all shaped our human civilization.
                                </Typography>
                                <a href="#" className={classes.FD_linkColor+" "+classes.FD_aboutLink}>CONTINUE READING...</a>
                        </Grid>
                    </Grid>
                </Grid>



            </Grid>
            
            <Grid container xs={12} direction="column" className={classes.FD_featureSection} >
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        DEENFUND FEATURES
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        We are committed to provide an easy, effective and secured platform for donors and
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        organizations. Here are few of our awesome services.
                    </Typography>

                   <Grid container xs={12} alignItems="center" container direction="row" className={classes.featuredGird}>
                       <Grid container xs={12} spacing={3} >
                       <Grid item  className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon1} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    LESS WORK MORE DAWAH
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Our platform handles complete donation process. That leaves more time for you to focus on more dawah work, share DEENFUND with others and earn more hasanah.
                                </Typography>
                            </Grid>
                            <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon2} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    SAFE & SECURE
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    We've partnered with well-reputed payment processing company Stripe to process your donations in most secured way and complaint with all regulations.
                                </Typography>
                            </Grid>
                            <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon3} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    EASY & FAST
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    First impression is the best impression. We spent tons of hours to built the most user-friendly and easy to use platform for both donors and organizations for remarkable donation experience, ever.
                                </Typography>
                            </Grid>
                            <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon4} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    TRANSPARENCY
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    It's simple. There's nothing hidden. You will be able view your donations, donation history, change donation period and much more from your account.
                                </Typography>
                            </Grid>
                            <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon5} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    TAX EXEMPTION
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    With simple steps, you will be able to request for Tax Exemption Certificate from the organizations you donated generously at any point of time.
                                </Typography>
                            </Grid>
                            <Grid item className={classes.FD_FeaturesPoint} md={4} sm={6} xs={12}>
                                <img className={classes.FD_featureImg} src={Icon6} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    GREAT REWARD
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Charity does not in any way decrease the wealth, rather giving regular charity out of what we have, purifies our wealth, and provide share on the Day or Resurrection [At-Tirmidhi]
                                </Typography>
                            </Grid>

                       </Grid>



                   </Grid>
            </Grid>

            <Grid container xs={12} direction="column" className={classes.FD_DonorSection} >
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        HOW IT WORKS FOR DONOR
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        Our donation platform is here to guide your every step of the way and make
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        your donation process easy and simple
                    </Typography>

                    <Grid container xs={12} alignItems="center" spacing={3} container direction="row" className={classes.FD_DonorMenuSection}>
                        
                        <Grid container xs={12} direction="row" spacing={3} style={{padding: '24px'}} >
                        
                        <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={amount} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    CHOOSE DONATION AMOUNT
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Either from online, mobile or on-site in an organization, simply select or enter a specific one-time or recurring amount you want to donate to the choice of your organization.
                                </Typography>
                        </Grid>

                        <Grid item  className={classes.FD_DonorPart+" "+classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={org} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    SEARCH ORGANIZATION
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Select the choice of your organizations to receive your generous donations. And if you are not able to find the organization, please introduce DEENFUND to the organization’s team members.
                                </Typography>
                                <img className={classes.FD_featureDownArrowImg} src={featureDownArrow} alt="" />

                        </Grid>

                        <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={donation} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    SUBMIT DONATIONS
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    We’ve partnered with secured donation processing platform Stripe. Enter your Credit or Debit Card details, and submit your donations.
                                </Typography>
                        </Grid>

                        <Grid item  className={classes.FD_DonorPart+" "+classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={others} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    SHARE & MOTIVATE OTHERS
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Let others get motivated from your contribution. Share the awesome news with your friends and families and earn more hasanah.
                                </Typography>

                        </Grid>
                        <Button ariant="contained" className={classes.FD_signUpNow} >
                            SIGN UP NOW
                        </Button>

                    </Grid>
                </Grid>

            </Grid>

            <Grid container xs={12} direction="column" className={classes.FD_DonorSection} >
                    <Typography className={classes.FD_aboutTitle} variant="h1" component="h1">
                        HOW IT WORKS FOR ORGANIZATION
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        Our donation platform is here to guide your every step of the way and make
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        your donation process easy and simple
                    </Typography>

                    <Grid container xs={12} alignItems="center" spacing={3} container direction="row" className={classes.FD_DonorMenuSection}>
                        
                        <Grid container xs={12} direction="row" spacing={3} style={{padding: '24px'}} >
                        
                        <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={amount} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    VERIFY YOUR ORGANIZATION
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    To show appreciation to your donors, we provide Thank You notes, which you can find on your Dashboard. You can easily leave a personal note to express your gratitude for each and every donation you receive.
                                </Typography>
                        </Grid>

                        <Grid item  className={classes.FD_DonorPart+" "+classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={org} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    LINK ORG. BANK ACCOUNT
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    We’ve partnered with secured donation processing platform Stripe. Connect your bank account to start collecting donations from amazing donors.
                                </Typography>
                                <img className={classes.FD_featureDownArrowImg} src={featureDownArrow} alt="" />

                        </Grid>

                        <Grid item className={classes.FD_DonorPart} md={3} sm={6} xs={12}>
                                <img className={classes.FD_featureArrowImg} src={PointingArrow} alt="" />
                                <img src={donation} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    COLLECT DONATIONS
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    Start collecting one-time and monthly donations from online or from your website with custom buttons - directly on every single page of your website and your own embedded donation form.
                                </Typography>
                        </Grid>

                        <Grid item  className={classes.FD_DonorPart+" "+classes.FD_secondDonor} md={3} sm={6} xs={12}>
                                <img src={others} alt="" />
                                <Typography className={classes.FD_PointTitle} variant="h3" component="h3">
                                    THANK YOUR DONORS
                                </Typography>
                                <Typography className={classes.FD_PointText} variant="body1" component="p">
                                    To show appreciation to your donors, we provide Thank You notes, which you can find on your Dashboard. You can easily leave a personal note to express your gratitude for each and every donation you receive.
                                </Typography>

                        </Grid>
                        <Button ariant="contained" className={classes.FD_signUpNow} >
                            SIGN UP NOW
                        </Button>

                    </Grid>
                </Grid>

            </Grid>

            <Grid container xs={12} direction="column" className={classes.FD_adSection} >
                    <Typography className={classes.FD_adTitle} variant="h1" component="h1">
                        AD SPACE
                    </Typography>
            </Grid>

            <Grid container xs={12} direction="column" className={classes.FD_percentage} >
                    <Typography className={classes.FD_percentageTitle} variant="h1" component="h1">
                        DO MORE AWESOME WORK WITH
                    </Typography>
                    <Typography className={classes.FD_percentageTitle} variant="body1" component="p">
                        <b>SIMPLE &amp; LESS FEE</b>
                        <Divider className={classes.FD_line} />
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        We are a global platform connecting awesome donors with amazing
                    </Typography>
                    <Typography className={classes.FD_aboutPara} variant="body1" component="p">
                        organizations with great causes.
                    </Typography>

                    <Grid container xs={12} alignItems="center" spacing={3} container direction="row" className={classes.sws_work}>
                        
                        <Grid container className={classes.percentage} xs={12} direction="row" spacing={3} style={{padding: '24px'}} >
                        
                        <Grid item className={classes.prc_col} md={3} sm={6} xs={12}>
        
                            <Typography className={classes.percentage_span} variant="body2" component="span">70
                                <Typography variant="body1" component="label" className={classes.percentage_span_label}>% <Typography variant="body1" component="p">LESS</Typography></Typography>
                            </Typography>
                            <Typography variant="h3" component="h3" >Than Most Platforms</Typography>

                        </Grid>



                    </Grid>
                </Grid>

            </Grid>



        </React.Fragment>
            
    );
}

export default AboutUs;