
import React, { Component } from 'react'
import axios from 'axios'
import Recaptcha from 'react-google-invisible-recaptcha'
import Grid from '@material-ui/core/Grid'
import LockIcon from '@material-ui/icons/Lock'
import { Button, Input } from '../../components'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import { stripTokenGet } from './apis'
import './css/paymentinfo.css'
import { connect } from 'react-redux'
import { updateErrorFlag } from '../../store/users/actions'
import swal from 'sweetalert2'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// window.$recaptcha = React.createRef();

String.prototype.capitalize = function () {
  var sa = this.replace(/-/g, ' ')
  var saa = sa.toLowerCase()
  var sb = saa.replace(/(^|\s)([a-z])/g, function (m, p1, p2) { return p1 + p2.toUpperCase() })
  var sc = sb.replace(/\s+/g, ' ')
  return sc
}

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isProceed: true,
      title: 'Card information',
      cardNumber: '',
      cardHolderName: '',
      expMonth: '',
      expYear: '',
      cvvCode: '',
      amount: 0,
      error: false,
      errorMsg: '',
      cvvErrorMsg: '',
      zipCode: '',
      expDate: '',
      width: '',
      loading: false,
      value: '',
      token: false,
      tooltipFlag: false,
    }
    this.proceesNext = this.proceesNext.bind(this)
    this.stripTokenApiHit = this.stripTokenApiHit.bind(this)
    this.handleExpDate = this.handleExpDate.bind(this)
    this.onResolved = this.onResolved.bind(this)
    this._reCaptchaRef = React.createRef()
  }

  componentDidMount() {
    // this.setCompanyId()
    this.setPayment()
    window.addEventListener('resize', this.resize.bind(this))
    this.resize()

  }

  resize() {
    const currentWidth = (window.innerWidth <= 599)
    this.setState({ width: currentWidth })
  }

  // async setCompanyId() {
  //   var urlParams = new URLSearchParams(window.location.search)
  //   if (urlParams.has('orgId')) {
  //     this.setState({ orgId: urlParams.get('orgId') })
  //   }
  // }

  setPayment() {
    const { payment: { amount } } = this.props
    // const calculation = fee ? +amount + (+amount * 0.029) + 0.3 + (+amount * 0.0175) : +amount
    this.setState({ amount: +amount })
  }
  

  async stripTokenApiHit() {
    let minimumAmount = this.props.org?.org?.embedForm && this.props.org?.org.embedForm?.[0]?.donationMinimumAmount;
    minimumAmount = minimumAmount ? parseInt(minimumAmount) : 1;
    const { cardNumber, cardHolderName, expMonth, expYear, cvvCode, expError,zipCode } = this.state;
    let newNumber = cardNumber.replace(/ +/g, "");
    const {
      payment: { category, donationType: frequency, categoryId,orgId, fee, amount, note },
      userInfo: { firstName, lastName, email, phoneNo, country }
    } = this.props;

    let userData = {};
    try {
    const userRes = await fetch('https://ipinfo.io/json?token=989c1db047c5cf');
    userData = await userRes.json();
    
    console.log("country_code", userData?.country?.toLowerCase());
    } catch (error) {
      console.log("error", error)
    }

    if (!cardNumber || !cardHolderName || !expMonth || !expYear || !cvvCode || !firstName || !lastName || !phoneNo || phoneNo.length < 11 || !categoryId  || amount < minimumAmount || expError) {
      this.setState({
        error: true
      })

      this.recaptcha.reset()
      this.props.updateErrorFlag(true)
    }
    else if ((cardNumber[0] !== '3' && newNumber.length < 16) || (cardNumber[0] === '3' && newNumber.length < 14)) {

      this.setState({
        cardError: true
      })
    }
    else if ((cardNumber?.startsWith('37') && cvvCode.length < 4) || (!cardNumber?.startsWith('37') && cvvCode.length < 3)) {

        this.setState({
          error: true
        })
      }
    else {
      const cardInfo = {
        number: cardNumber.replace(/\s/g, ''),
        exp_month: expMonth,
        exp_year: +('20' + expYear),
        cvc: cvvCode
      }
      const chargeInfo = {
        amount,
        orgId,
        phoneNo,
        description: `${frequency}/${category}`,
        frequency: frequency.replace(' ', '-'),
        categoryId,
        processingFee: fee,
        memoNote: note || ' ',
        firstName,
        lastName,
        // email: email || 'test@bync.com',
       billing_details:{
         address:{
           postal_code: zipCode,
           country: country,
           ip_country: userData?.country?.toLowerCase() ?? "",
           city: userData?.city?.toLowerCase() ?? "",
           state:userData?.region?.toLowerCase() ?? ""
         }
       }
      };
   
      if(email){
        chargeInfo.email=email
      }

      try {
       
        if (!this.state.token) {
          this.recaptcha.execute()
        } else {
          this.setState({ loading: true })

          const res = await stripTokenGet(cardInfo, chargeInfo)
          this.setState({ loading: false })
          if (res.data.success) {
            this.props.finishPayment(res.data.body.data)
            this.props.hideAlert()
          } else {
            this.setState({
              error: false,
              isProceed: true,
              title: 'Card information',
              errorMsg: res.data.body.message
            })
            this.props.showAlert('error')
            setTimeout(() => {
              this.props.hideAlert()
            }, 5000)
          }
        } 
      } catch (e) {
        console.log("sss", e)
        this.setState({
          error: false,
          loading: false,
          isProceed: true,
          title: 'Card information'
          // errorMsg: 'Something went wrong! Please try again with valid information.'
        })

        this.props.showAlert('error')
        this.props.hideAlert()
        // setTimeout(() => {

        // },4000)
      }
    }
  }

  handleExpDate(e) {
    // ignore invalid input
    if (!/^\d{0,2}\/?\d{0,2}$/.test(e.target.value)) {
      return
    }
    let input = e.target.value
    if (/^\d{3,}$/.test(input)) {
      input = input.match(new RegExp('.{1,2}', 'g')).join('/')
    }
    const [expMonth, expYear] = input.split('/')
    if (expMonth > 12) return
    const expFullYear = '20' + expYear
    const date = new Date()
    this.setState({
      expError: expFullYear.length === 4 && expFullYear < date.getFullYear() && 'Invalid exp date'
    })
    if (+expFullYear === date.getFullYear()) {
      this.setState({
        expError: expMonth < date.getMonth() + 1 && 'Invalid exp date'
      })
    }
    this.setState({
      expDate: input,
      expMonth: Number(expMonth),
      expYear: Number(expYear)
    })
  }

  validateExp() {
    const { expYear, expError } = this.state
    if (!expError) {
      this.setState({
        expError: expYear.toString().length !== 2 && 'Incomplete Exp date'
      })
    }
  }

  handleCvv(e) {
    const { cardNumber } = this.state
    const cvvCode = e.target.value
    if (cvvCode.length > 2 && (cardNumber?.startsWith('37') ? cvvCode.length > 4 : cvvCode.length > 3)) return
    this.setState({ cvvCode: e.target.value })
  }

  onResolved() {
    if (this.recaptcha.getResponse()) {
      this.setState({ token: true }, () => {
        this.stripTokenApiHit() // this.stripTokenApiHit()
      })
    }
    // axios.post('https://www.google.com/recaptcha/api/siteverify',{
    //   secret:'6LdMpu0UAAAAABCoc0BbMc6sGcnhkq4OpUKTWir2',
    //   response:this.recaptcha.getResponse()
  }

  proceesNext() {
    const { cardNumber, cardHolderName, expMonth, expYear, cvvCode, zipCode } = this.state
    if (!cardNumber || !cardHolderName || !expMonth || !expYear || !cvvCode || !zipCode) {
      this.setState({
        error: true
      })

    } else {
      if (cardNumber?.startsWith('37') ? cvvCode.length !== 4 : cvvCode.length !== 3) {
        this.setState({
          error: true,
          cvvErrorMsg: 'Enter valid CVV Code'
        })
        return
      }
      this.setState({
        error: false,
        isProceed: false,
        cvvErrorMsg: false,
        title: 'Your Billing Address'
      })
    }
  };


  creditCardFormat(card, regex) {

    if (!card) return card
    if (card[0] === '3') {

      let str = card;
      let str2;
      if (str.length === 4) {
        str = str + ' '
        return str
      }
      else if (str.length === 10) {
        str = str + ''
        return str
      }
      else if (str.length === 11) {
        str = str + ' '
        return str
      }
      // else if(str.length === 18){
      //   return false
      // }

      else {
        return str
      }

    }

    const cardWithoutSpace = card.replace(/\s/g, '')
    const cardTemp = cardWithoutSpace.match(/.{1,4}/g)
    return cardTemp.join(' ')
  }

  render() {
    const {
      isProceed,
      cardNumber,
      cardHolderName,
      expMonth,
      expYear,
      cvvCode,
      zipCode,
      error,
      cvvErrorMsg,
      errorMsg,
      expDate,
      width,
      expError,
      cardError,
      loading,
      tooltipFlag
    } = this.state
    const {
      verified, payment: { category, donationType: frequency, categoryId, fee, amount },
      userInfo: { firstName, lastName, email, phoneNo },
      org
    } = this.props
    const isValid = categoryId && firstName && lastName && email && phoneNo && amount >= 1;
    const customColor = org?.org?.embedForm && org?.org.embedForm?.[0]?.formThemeColor;
    // let minimumAmount = this.props.org?.org?.embedForm && this.props.org?.org.embedForm?.[0]?.donationMinimumAmount;
    // minimumAmount = minimumAmount ? parseInt(minimumAmount) : 1;

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 30
          }}
        >

          {/* {isProceed && <PaymentCardInfo amount={amount} />} */}
          {/* <div class='right_col_title' style={{ width: 490 }}>
            <p style={{ marginLeft: -35, marginBottom: 3 }}>{title}</p>
          </div> */}

          <div>
            {isProceed ? (
              <div className='creditCard'>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      value={cardHolderName}
                      label='Name on Card'
                      type='text'
                      onChange={(e) => {
                        const nameRegx = /^[a-zA-Z\s\.]*$/
                        if (e.target.value.length <= 30 && e.target.value.match(nameRegx)) {
                          this.setState({ cardHolderName: e.target.value.capitalize() })
                        }
                      }
                      }
                      helperText={!error ? 'Full name as shown on credit card' : ''}
                      errorText={(error && !cardHolderName && 'Name on card is required') || '' }
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      placeholder=""
                      label='Zip/Postal Code'
                      type='text'
                      value={zipCode}
                      onChange={(e) => {
                        const numRegx = /^[\d-]+$/
                        if (e.target.value.length && e.target.value.length <= 9 && numRegx.test(e.target.value) ) {
                          this.setState({ zipCode: e.target.value.toUpperCase() })
                        } else if (e.target.value.length < 1) {
                          this.setState({ zipCode: "" })
                        }
                      }
                      }
                      helperText={!error ? 'From your billing address' : ''}
                      errorText={(error && (!zipCode || zipCode.length < 9)  && 'Zip/postal code is required') || ''}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      placeholder=""
                      icon={cardNumber[0] === '4' && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/visa-icon.PNG')} alt="card-icon" /> 
                      || cardNumber[0] === '2' && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/mastercard2.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('51') || cardNumber?.startsWith('52') || cardNumber?.startsWith('53') || cardNumber?.startsWith('54') || cardNumber?.startsWith('55')) && <img style={{ width: '34px', height: '30px', border: '1px solid #000066', backgroundColor: '#000066' }} src={require('../../assets/rawimg/mastercard-icon.png')} alt="card-icon" />
                      || (cardNumber?.startsWith('37') || cardNumber?.startsWith('34')) && <img className="field_card" style={{ width: '', height: '' }} src={require('../../assets/rawimg/american-express.png')} alt="card-icon" /> 
                      || cardNumber?.startsWith('60') && <img style={{ width: '34px', height: '30px' }} src={require('../../assets/rawimg/discover.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('2131') || cardNumber?.startsWith('1800') || cardNumber?.startsWith('35')) && <img style={{ width: '34px', height: '30px' }} src={require('../../assets/rawimg/jcb-card.png')} alt="card-icon" /> 
                      || cardNumber?.startsWith('62') && <img style={{ width: '36px', height: '28px' }} src={require('../../assets/rawimg/unionpay.png')} alt="card-icon" /> 
                      || (cardNumber?.startsWith('30') || cardNumber?.startsWith('36')) && <img style={{ width: '36px', height: '32px' }} src={require('../../assets/rawimg/diners-club.png')} alt="card-icon" /> 
                    }
                      value={cardNumber}
                      label={"Card Number"}
                      onBlur={() => {
                        if(cardNumber.length !== 0){
                          const isError = cardNumber.length < 14
                          this.setState({ cardError: isError })
                        }
                      }}

                      inputMode='numeric'

                      helperText={!errorMsg && !cardError && !error ? 'Enter valid card number' : ''}
                      errorText={errorMsg === 'restricted_card' ? 'The card is restricted' : errorMsg === 'expired_card' ? 'The card has expired' : (errorMsg === 'incorrect_number' || cardError) ? 'Enter valid card number' : ((error && !cardNumber && 'Enter valid card number') || (cardError && 'Enter valid card number'))}

                      onChange={(e) => {
                        if (e.target.value.replace(/\s/g, '').match(/^\d+$/)) {
                          const card = this.creditCardFormat(e.target.value)
                          const cardWithoutSpace = card.replace(/\s/g, '')
                          this.setState({
                            cardNumber: cardWithoutSpace.length > 16 ? cardNumber : card,
                            /* cardError:(cardNumber[0] !== '3' && cardWithoutSpace.length !== 16) || (cardNumber[0] === '3' && cardWithoutSpace.length !== 15), */
                            cvvCode: !e.target.value?.startsWith('37') && cvvCode.length >= 4 ? cvvCode.slice(0, 3) : cvvCode
                          })
                        }

                        else if (!e.target.value) {
                          this.setState({
                            cardNumber: ''
                          })
                        }
                      }}

                      onKeyDown={(e) => {
                        if (cardNumber[0] === '3') {
                          if (e.keyCode === 8) {
                            if (cardNumber.length === 12) {
                              let newCardNumber = cardNumber.substring(0, 11)
                              this.setState({ cardNumber: newCardNumber })
                            }
                            else if (cardNumber.length === 5) {
                              let newCardNumber = cardNumber.substring(0, 4)
                              this.setState({ cardNumber: newCardNumber })
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Input
                      placeholder='MM / YY'
                      value={expDate}
                      onChange={(e) => {
                        this.handleExpDate(e)
                      }}
                      onBlur={() => this.validateExp()}
                      label='Exp Date'
                      type='text'
                      inputMode='numeric'
                      errorText={!expDate && error ? 'Exp date is required' : errorMsg === 'invalid_expiry_month' ? 'Invalid Expiry Month' : errorMsg === 'invalid_expiry_year' ? 'Invalid expiry Year' : expError || (!expYear && error ? 'Invalid expiry date' : '') }
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Input
                      onChange={(e) => {
                        const numRegx = /^[0-9]*$/
                        if(e.target.value.match(numRegx)){
                          this.handleCvv(e)
                        }
                      }}
                      placeholder='CVV'
                      value={cvvCode}
                      label='CVV'
                      inputMode='numeric'
                      // onBlur={() => this.validateCVV()}
                      /* helperText={!error ? 'Enter CVV Code' : ''} */
                      icon={<InputAdornment>
                      <ClickAwayListener onClickAway={() => this.setState({tooltipFlag: false})}>
                        <Tooltip  open={tooltipFlag} title="Your card's CVV is a 3 or 4 digit number, usually on the back next to your signature" placement='top-start'>
                          <img
                          onClick={() => this.setState({tooltipFlag: true})}
                          onMouseEnter={() => this.setState({tooltipFlag: true})}
                          onMouseOut={() => this.setState({tooltipFlag: false})}
                           src={require('../../assets/rawimg/help-24px.svg')} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                        </ClickAwayListener>
                      </InputAdornment>}
                      errorText={!cvvCode && error && 'CVV code is required' || (errorMsg === 'incorrect_cvc' && 'Incorrect CVV') || (errorMsg === 'invalid_cvc' && 'Invalid CVV code') || (error && !cvvCode && 'Invalid CVV code') || (error && cardNumber?.startsWith('37') && cvvCode.length < 4 && 'Invalid CVV code') || (error && !cardNumber?.startsWith('37') && cvvCode.length < 3 && 'Enter valid CVV code') || (error && cvvErrorMsg)}
                    />
                  </Grid>

                </Grid>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', width: '532px', marginLeft: '-4px' }}
                >

                  {/* <SelectInput
                    onChange={(e) => this.setState({ expMonth: e.target.value })}
                    label='Exp. Month'
                    helperText={!expMonth && error ? 'Select Expiry Month' : ''}
                    option={
                      monthNme.map((item, index) => {
                        return <option key={item + '-month'} value={index + 1}>{index + 1}</option>
                      })
                    }
                  />
                  <SelectInput
                    onChange={(e) => this.setState({ expYear: e.target.value })}
                    label='Exp. Year'
                    helperText={!expYear && error ? 'Select Expiry Year' : ''}
                    option={
                      years.map((item, index) => {
                        return <option key={item + '-year'} value={item.year}>{item.year}</option>
                      })
                    }
                  /> */}

                </div>
                <Recaptcha
                  style={{ zIndex: 999 }}
                  ref={ref => this.recaptcha = ref}
                  sitekey='6LeXoO0UAAAAAKxhLI7XQ1Drf4CMNBp1HjwgJFhf'
                  onResolved={this.onResolved}
                />

                <div className="border_submit_btn"></div>

                <div className='submit_btn' style={{ textAlign: 'center' }}>
                  {!loading ? <Button
                    // disabled={!isValid}
                    onClick={() => this.stripTokenApiHit()}
                    title='Submit Donation'
                    widthHeight={!width ? [510, 60] : [470, 60]}
                    customColor={customColor}
                    icon={<LockIcon />}
                    className='checkout'
                  /> : <CircularProgress color='primary' />}
                </div>
                <p className='lastText'>By donating you agree to our <a target="_blank" href='https://deenfund.com/terms-policies'>Terms of Services</a> and <a target="_blank" href='https://deenfund.com/privacy-policy'> Privacy Policy</a></p>
                <p className='lastText'>This site is protected by reCAPTCHA and the Google <a target="_blank" href='https://policies.google.com/privacy?hl=en'>Privacy Policy</a> and <a target="_blank" href='https://policies.google.com/terms?hl=en'> Terms of Service </a>apply</p>

              </div>
            ) : (
                {/* <>
                  <Input
                    label=' Street Address '
                    type='text'

                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Input
                      label=' City/Town'
                      type='text'

                      style={{ width: '240px' }}
                    />

                    <Input
                      label='State/Province'
                      type='text'

                      style={{ width: '240px' }}
                    />
                  </div>
                  <Input
                    label=' Zip Code '
                    type='text'

                  />
                  <p style={{ color: '#818E94', lineHeight: '20px', fontFamily: 'Roboto-Regular', fontSize: 16, marginLeft: 5 }}>
                    By Donating you agree our
                  <span style={{ color: '#05CE78' }}>{' '} Terms & Conditions</span> and{' '}
                    <span style={{ color: '#05CE78' }}>{' '}Privacy policy</span>
                  </p>
                  <Button
                    onClick={() => this.stripTokenApiHit()}
                    title='proceed Next'
                    widthHeight={[510, 60]}
                    icon={<LockIcon />}
                  />
                </> */}
              )}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.users,
    user: state.users && state.users.userAsGuest,
    phoneNo: state.users.phoneNo,
    payment: state.payment,
    editFlag: state.steps,
    errorFlag: state.users.errorFlag,
    org: state.org
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateErrorFlag: (errorFlag) => dispatch(updateErrorFlag(errorFlag))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
