import React, { Component } from "react";
import "./css/Donor.css";
import LockIcon from "@material-ui/icons/Lock";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  updateFirstName,
  updateLastName,
  updateEmail,
  updateCountry,
  updateUserAsGuest,
} from "../../store/users/actions";
import {
  Button,
  Input,
  Verification,
  Signup,
} from "../../components";
import "./css/errors.css";
import { BasePhoneInput } from "master-react-lib";
import 'react-phone-input-2/lib/material.css'
import "./css/style.css";
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


String.prototype.capitalize = function () {
  var sa = this.replace(/-/g, " ");
  var saa = sa.toLowerCase();
  var sb = saa.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
  var sc = sb.replace(/\s+/g, " ");
  return sc;
};

class Donor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhoneverify: true,
      eptype: {
        type: "phone",
        title: "Enter phone number",
        userContact: "",
        description: "",
      },
      issignUp: false,
      email: "",
      password: "",
      phoneNumber: "",
      phone: "",
      num: "",
      error: false,
      emailError: true,
      emailErrorMessage: "",
      labelFlag: false,
      countryAlphaCode: "",
      checkError: false,
      iscountryCode: "",
      dialCode: "",
      countryFlag: "",
      userIpCountryCode: ""
    };

    this.handleisPhoneverify = this.handleisPhoneverify.bind(this);
    this.handleIsSignUp = this.handleIsSignUp.bind(this);
  }

  async componentDidMount() {
    try {
      const userRes = await fetch('https://ipinfo.io/json?token=989c1db047c5cf');
    
      const userData = await userRes.json();
      // console.log("userData", userData, userData?.country)
  
      if(userData?.country) {
        this.setState({
          userIpCountryCode: userData?.country?.toLowerCase()
        })
      } else {
        this.setState({
          userIpCountryCode: "us"
        })
      }
    } catch (error) {
      console.log("error", error)
      this.setState({
        userIpCountryCode: "us"
      })
    }
  }

  handleisPhoneverify(verifyObj) {
    this.setState({ isPhoneverify: true, eptype: verifyObj });
  }

  handleIsSignUp() {
    const { issignUp } = this.state;
    this.setState({ issignUp: !issignUp });
  }

  handleLogin() {
    const { email, password } = this.state;
    if (email && password) {
      this.props.forNextStep();
    } else {
      this.setState({ error: true });
    }
  }
  render() {
    const emailRegex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    const {
      isPhoneverify,
      eptype,
      issignUp,
      email,
      phoneNumber,
      password,
      num,
      emailError,
      emailErrorMessage,
      phoneError,
      countryAlphaCode,
      phone,
      userIpCountryCode
    } = this.state;
    const {
      user,
      payment: { amount, category },
      userInfo,
      errorFlag,
    } = this.props;
    const verifyObj = {
      email: {
        type: "email",
        title: "Reset Passward",
        userContact: user.email,
        description:
          "Don`t worry, it happens to best of us. Enter your email address below and continue",
      },
      phone: {
        type: "phone",
        title: "Enter phone number",
        userContact: user.connactNumber,
        description:
          "Please enter your phone number and verify to complete donation as guest",
      },
    };

    return (
      <>
        {!issignUp && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {!isPhoneverify && (
              <>
                <div className="right_col_title">Login To Donate</div>
                <div style={{ width: 527, alignSelf: "center" }}>
                  <Input
                    label="Email"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                  <Input
                    label=" Enter Password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />

                  <center>
                    <div style={{ marginBottom: 20 }}>
                      <a
                        onClick={() =>
                          this.handleisPhoneverify(verifyObj.email)
                        }
                        href="javascript:void(0)"
                        style={{
                          color: "#818E94",
                          textDecoration: "none",
                          marginTop: "15px",
                          marginBottom: "25px",
                        }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </center>

                  <Button
                    title="Login To Deenfund"
                    widthHeight={[510, 60]}
                    onClick={() => this.handleLogin()}
                    icon={<LockIcon />}
                  />

                  <p
                    style={{
                      width: "80%",
                      color: "#BFCED7",
                      lineHeight: "24px",
                      fontSize: "12px",
                      fontFamily: "Roboto-Regular",
                      marginLeft: "10px",
                    }}
                  >
                    Logging to your DEENFUND account will Save your donation
                    history and request for tax exemption letter at year end
                    from organizations.
                  </p>

                  <Button
                    title="Donate AS Guest"
                    widthHeight={[510, 60]}
                    variant="outlined"
                    style={{
                      fontFamily: "Roboto-Bold",
                      fontSize: "20px",
                      letterspace: "10px",
                      color: "#6C8FA4",
                    }}
                    onClick={() => this.handleisPhoneverify(verifyObj.phone)}
                  />

                  <center>
                    <p
                      style={{
                        color: "#818E94",
                        lineHeight: "20px",
                        fontFamily: "Roboto-Regular",
                      }}
                    >
                      Don't have an account yet?{" "}
                      <span
                        onClick={this.handleIsSignUp}
                        style={{
                          color: "#05CE78",
                          cursor: "pointer",
                          fontFamily: "Roboto-Medium",
                        }}
                      >
                        JOIN DEENFUND
                      </span>
                    </p>
                  </center>
                </div>
              </>
            )}
            <div style={{ width: "100%" }}>
              {isPhoneverify && (
                <Verification
                  email={email}
                  showAlert={this.props.showAlert}
                  phoneNumber={phoneNumber}
                  propsForNav={this.props}
                  inputField={
                    eptype.type === "email" ? (
                      <Input
                        label="Email"
                        type="text"
                        /* helperText='Helper text' */
                        onChange={(e) =>
                          this.setState({
                            email: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Input
                              label="First Name"
                              type="text"
                              value={userInfo.firstName}
                              onChange={(e) => {
                                const nameRegx = /^[a-zA-Z\s\.]*$/;
                                if (
                                  e.target.value.length < 21 &&
                                  e.target.value.match(nameRegx)
                                ) {
                                  const spaceCount =
                                    e.target.value.split(" ").length - 1;
                                  // this.props.updateFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                  // this.setState({ firstName: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)})
                                  if (spaceCount < 3) {
                                    this.props.updateFirstName(
                                      e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : ""
                                    );
                                    this.setState({
                                      firstName: e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : ""
                                    });
                                  }
                                }
                              }}
                              // helperText={!error ? 'Full Name as hown on Credit/Debit card' : ''}
                              errorText={
                                userInfo.errorFlag &&
                                !userInfo.firstName &&
                                "First name is required "
                              }
                            />
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Input
                              label="Last Name"
                              type="text"
                              value={userInfo.lastName}
                              onChange={(e) => {
                                // this.props.updateLastName(e.target.value)
                                // this.setState({ lastName: e.target.value })}
                                const nameRegx = /^[a-zA-Z\s\.]*$/;
                                if (
                                  e.target.value.length < 21 &&
                                  e.target.value.match(nameRegx)
                                ) {
                                  const spaceCount =
                                    e.target.value.split(" ").length - 1;
                                  if (spaceCount < 3) {
                                    this.props.updateLastName(
                                      e.target.value ? e.target.value[0].toUpperCase() + e.target.value.substring(1,21) : ""
                                    );
                                    this.setState({
                                      lastName: e.target.value ? e.target.value[0]?.toUpperCase() + e.target.value?.substring(1,21) : "",
                                    });
                                  }
                                }
                              }}
                              // helperText={!error ? 'From your billing address' : ''}
                              errorText={
                                userInfo.errorFlag &&
                                !userInfo.lastName &&
                                "Last name is required"
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12} style={{marginTop: "-7px"}}>
                            {/* <br /> */}
                            {/* <PhoneInput /> */}
                            <BasePhoneInput
                              phoneInputVariant={"materialui"}
                              countryCodeEditable={false}
                                inputStyle={{
                                marginLeft: "0px",
                                // marginTop: "-7px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                              }}
                              onBlur={() => {
                                // console.log("phone", phone)
                                // console.log(phoneUtil.isValidNumberForRegion(phone, 'US'));

                                // console.log("phone", phone, isPossiblePhoneNumber(phone, countryAlphaCode))
                                // if (countryAlphaCode === "us") {
                                  if (phone && phone.length < 11) {
                                    this.setState({
                                      phoneError: "Invalid phone number",
                                      error: true,
                                    });
                                  } else {
                                    this.setState({
                                      phoneError: false,
                                      error: false,
                                    });
                                  }
                                // }

                                // if (countryAlphaCode === "ca") {
                                //   if (phone && phone.length !== 11) {
                                //     this.setState({
                                //       phoneError: "Invalid phone number",
                                //       error: true,
                                //     });
                                //   } else {
                                //     this.setState({
                                //       phoneError: false,
                                //       error: false,
                                //     });
                                //   }
                                // }

                                // if (countryAlphaCode === "my") {
                                //   if (phone && phone.length !== 12) {
                                //     this.setState({
                                //       phoneError: "Invalid phone number",
                                //       error: true,
                                //     });
                                //   } else {
                                //     this.setState({
                                //       phoneError: false,
                                //       error: false,
                                //     });
                                //   }
                                // }

                                // if (countryAlphaCode === "gb") {
                                //   if (phone && phone.length !== 12) {
                                //     this.setState({
                                //       phoneError: "Invalid phone number",
                                //       error: true,
                                //     });
                                //   } else {
                                //     this.setState({
                                //       phoneError: false,
                                //       error: false,
                                //     });
                                //   }
                                // }
                              }}
                              variant="outlined"
                              country={userIpCountryCode}
                              onlyCountries={[]}
                              value={this.state.phone}
                              onKeyDown={(e) => {
                                this.setState({ num: e.target.value })
                                // console.log("onKeyDown", e.target.value)
                              }
                              }
                              onChange={(phone, country) => {
                                // console.log("onChange", phone, country)
                                // console.log(countryAlphaCode, country.countryCode)
                                // if (this.state.countryAlphaCode !== country.countryCode) {
                                //   console.log("country.dialCode", country.dialCode)

                                //   this.setState({
                                //     phone: country.dialCode,
                                //     countryAlphaCode: country.countryCode,
                                //   });
                                //   this.props.updateUserAsGuest({}, phone);
                                // } else {
                                  this.setState({
                                    phone: phone,
                                    countryAlphaCode: country.countryCode,
                                  });
                                  if(country?.countryCode) {
                                    this.props.updateCountry(country.countryCode);
                                  }
                                  this.props.updateUserAsGuest({}, phone);
                                // }
                              }}
                              containerStyle={{
                                width: "100%",
                                height: "50px",
                                marginLeft: "18px",
                                color: "red",
                              }}
                              buttonStyle={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "3px",
                              }}
                              inputStyle={
                                this.state.error ||
                                (errorFlag && this.state.phone.length === 0)
                                  ? {
                                      marginLeft: "0px",
                                      height: "50px",
                                      width: "100%",
                                      fontFamily: "Roboto-Regular",
                                      fontSize: "16px",
                                      border: "1px solid red",
                                    }
                                  : {
                                      marginLeft: "0px",
                                      height: "50px",
                                      width: "100%",
                                      fontFamily: "Roboto-Regular",
                                      fontSize: "16px",
                                    }
                              }
                              containerClass={
                                (this.state.error ||
                                  (errorFlag &&
                                    this.state.phone.length === 0)) &&
                                "phoneErrorshow"
                              }
                              specialLabel={''}
                              errorText={phoneError ? phoneError : "Phone number is required"}
                              error={!phoneError && userInfo.errorFlag && !userInfo.phoneNo ? true : phoneError ? true : false}
                              errorStyles={{marginLeft: "10px", marginTop: "0px"}}
                            />
                            {/* {!phoneError &&
                              userInfo.errorFlag &&
                              !userInfo.phoneNo && (
                                <span
                                  className="tel_error_msg"
                                  style={{
                                    display: "flex",
                                    fontFamily: "Roboto-Regular",
                                    letterSpacing: 0.4,
                                    color: "red !important",
                                    fontSize: 12,
                                    textIndent: "5px",
                                    color: "#FF5353",
                                    paddingTop: 0,
                                    marginLeft: 5,
                                    marginTop: 1,
                                    position: "absolute",
                                  }}
                                >
                                  Phone number is required
                                </span>
                              )}
                            {phoneError && (
                              <span
                                className="tel_error_msg"
                                style={{
                                  display: "flex",
                                  fontFamily: "Roboto-Regular",
                                  letterSpacing: 0.4,
                                  color: "red !important",
                                  fontSize: 12,
                                  textIndent: "5px",
                                  color: "#FF5353",
                                  paddingTop: 0,
                                  marginLeft: 5,
                                  marginTop: 1,
                                  position: "absolute",
                                }}
                              >
                                {phoneError}
                              </span>
                            )} */}
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Input
                              onBlur
                              label="Email Address"
                              type="email"
                              helperText="Enter Email Address to receive confirmation (optional)"
                              value={email.toLowerCase()}
                              onChange={(e) => {
                                this.props.updateEmail(e.target.value);
                                this.setState({ email: e.target.value });
                                if (emailRegex.test(e.target.value)) {
                                  this.setState({
                                    email: e.target.value,
                                    emailError: false,
                                    checkError: false,
                                  });
                                } else {
                                  this.setState({
                                    email: e.target.value,
                                    emailError: true,
                                    emailErrorMessage: "",
                                    checkError: true,
                                  });
                                }
                              }}
                              onBlur={() =>
                                emailError
                                  ? this.setState({
                                      emailErrorMessage:
                                        "Invalid email address",
                                      checkError: true,
                                    })
                                  : this.setState({
                                      emailErrorMessage: "",
                                      checkError: false,
                                    })
                              }
                              // helperText='Enter email to receive confirmation (optional)'
                              helperText={
                                ((!email || email) &&
                                  !emailErrorMessage &&
                                  "Enter Email Address to receive confirmation (optional)") ||
                                !emailErrorMessage ||
                                (!email &&
                                  "Enter email to receive confirmation (optional)")
                              }
                              errorText={
                                email && emailError && emailErrorMessage
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )
                  }
                  eptype={eptype}
                  num={num}
                />
              )}
            </div>
          </div>
        )}
        {issignUp && (
          <>
            <Signup
              onClick={this.handleIsSignUp}
              onNextstep={() => this.props.forNextStep()}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.users.user,
    userInfo: state.users,
    payment: state.payment,
    errorFlag: state.users.errorFlag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateFirstName: (firstName) => dispatch(updateFirstName(firstName)),
    updateLastName: (lastName) => dispatch(updateLastName(lastName)),
    updateEmail: (email) => dispatch(updateEmail(email)),
    updateCountry: (country) => dispatch(updateCountry(country)),
    updateUserAsGuest: (otp, phoneNumber) =>
      dispatch(updateUserAsGuest(otp, phoneNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Donor);
