import React, { Component } from 'react'
import { Button, Input } from '../../components'
import LockIcon from '@material-ui/icons/Lock'
import './style.css'

export default class LoginCard extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div
        className='login-card'
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div className='sub-container'>
          <center>
            <p style={{ margin: '20px auto', fontSize: '24px', color: '#000' }}>
              <span className='login'>Login</span> To DEENCONNECT
            </p>
          </center>

          <Input label='Email' type='text' helperText='Helper text' />

          <Input
            label=' Enter Password'
            type='password'
            helperText='Helper text'
          />

          <div style={{ marginTop: '10px' }}>
            <Button
              title='Login To Deenconnect'
              widthHeight={[500, 60]}
              icon={<LockIcon />}
            />
          </div>

          <di style={{ marginTop: '5px' }}>
            <p
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '15px',
                fontSize: '16'
              }}
            >
              <a href='#' style={{ color: '#818E94' }}>
                Forget Password?
              </a>
              <p style={{ color: '#818E94', lineHeight: '5px' }}>
                Please reset the from the web-base DEENCONNECT Console
              </p>
            </p>
          </di>
        </div>
      </div>
    )
  }
}
