const INITIAL_STATE = {
  step: 0,
  flag: false,
  sideBarflag: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STEPS': {
      return { ...state, step: action.payload }
    }

    case 'SET_EDIT': {
      return { ...state, flag: action.payload.flag, step: action.payload.step }
    }

    case 'SET_SIDEBAR': {
      return { ...state, sideBarflag: action.payload }
    }

    default:
      return state
  }
}
