import React from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import './style.css'

function Logo (props) {
  return (
    //<div className='macfee_logo'><img src={require('../../assets/img/macfee_logo.png')} /></div>//
    <div className={props.top ? 'secured_text-top' :'secured_text'} > <LockOutlinedIcon /> SECURED BY <span>AWS</span></div>
  )
}

export default Logo
