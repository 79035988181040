import React, { Component } from 'react'
import {
  DonationIndex,
  Header,
  Footer,
  Notification,
  MacfeeLogo,
} from '../../components'
import SideBar from '../../components/Sidebar/Sidebar'
import { connect } from 'react-redux'
import {updateStatus} from '../../components/Donation/apis'
import './style.css'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      goBack: false,
      showAlert: false,
      type: '',
      image_or_video_link: ''
    }

    this.showAlert = this.showAlert.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    // this.goBack = this.goBack.bind(this)
   
  }

  async componentDidMount() {
     if(this.props?.match?.params?.invitedDonor) {
       await updateStatus(this.props.match.params.invitedDonor, 'Accepted');
      this.setState({
        type: 'invited',
        showAlert: true,
      })
    }
  }

  showAlert(type) {
    this.setState({
      showAlert: true,
      type: type,
    })
  }

  hideAlert() {
    this.setState({
      showAlert: false,
    })
  }



  // goBack() {
  //   this.setState({ goBack: true }, () => {
  //     this.setState({goBack: false})
  //   })
  // }

  render() {
    const { goBack, showAlert, type, image_or_video_link } = this.state
    const { sideBarflag } = this.props

    return (
      <>
        <div className={this.state.showAlert ? 'show' : 'hide'}>
          <Notification show={showAlert} type={type} />
        </div>
        <Header />
        <div className='bg'>

          <div className='sideBar'>
            <div className='donation-container'>
              <DonationIndex
                onBackPressed={goBack}
                steper
                nav={this.props}
                showAlert={this.showAlert}
                hideAlert={this.hideAlert}
              />

          <div className='logo-on-lg'>
            <MacfeeLogo sideBarflag />
          </div> 
            </div>

            {sideBarflag && <div className='sidebar_class'>
              {sideBarflag && <MacfeeLogo top />}
              <SideBar />
            </div>}
          </div>
          
          <center className='logo-on-sm'>
            <MacfeeLogo sideBarflag />
          </center>
        </div>
        <Footer />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    sideBarflag: state.steps.sideBarflag,
    org: state.org
  }
}

export default connect(mapStateToProps, null)(Home)
