import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    color: '#1A1A1A',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paraghText:{
      color: '#747474',
      lineHeight: '26px',
      fontSize: '14px',
      fontFamily: 'OpenSans-Regular'
  }
}));

export default function ControlledExpansionPanels(props) {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

    const sideBarData = props.faq

  return (
    <div className={classes.root}>
        {sideBarData && sideBarData.map((items , index) => {
            return(
                <ExpansionPanel key={items._id} expanded={expanded === items._id } onChange={handleChange(items._id)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>{items.question}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.paraghText}>
                    {items.answer}
                    {/* {items?.answer?.length > 50 ? items.answer.substring(0, 40) + "..." : items.answer} */}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
        })}
    </div>
  );
}
