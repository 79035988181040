import axios from 'axios'

const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
}

const sharedEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
}
// const paymentBaseUrl = 'https://2w0watsk73.execute-api.us-east-1.amazonaws.com/dev'
let currentEnv
if(window.location.host.includes('amazon') || window.location.host.includes('localhost') || window.location.host.includes('dev') ) {
  currentEnv = 'dev'
} else {
  currentEnv = 'prod'
}
const paymentBaseUrl = env[currentEnv]
const orgBaseUrl = sharedEnv[currentEnv]

export function getCompany (orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${paymentBaseUrl}/organizations/${orgId}`)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
