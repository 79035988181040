import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import brandLogo from '../../assets/img/Rectangle.png';




const useStyles = makeStyles((theme) => ({
    footer: {
        borderBottom: "1px solid #0000001A",
        background: "#f7f9fa",
        padding: "24px",
    },
    FD_footerInner: {
        maxWidth: "1140px",
        margin: "0 auto",
    },
    FD_FooterGrid: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
          }
    },
    FD_lowerfooter: {
        color: "#4C5E67",
        fontSize: "10px",
        lineHeight: "1.5",
        paddingRight: "10px",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            alignItems: "center"
          }
        
    },
    FD_smallText: {
        textAlign: "right",
        fontSize: "10px",
        color: "#4C5E67"
    },
    FD_dee: {
        position: "relative",
        top: "5px",
        width: "18px",
    }
    }));


const LowerFooter = () =>  {

    const classes = useStyles();
    return (
    <Grid container  className={classes.footer} >
        <Grid container className={classes.FD_footerInner} >
            <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                
                <Grid item md={3} sm={12} xs={12} >
                    <Typography  className={classes.FD_lowerfooter}>
                        TERMS OF SERVICES I PRIVACY POLICY COPYRIGHT © 2020 ∎ ALL RIGHT RESERVED
                    </Typography>
                </Grid>
                <Grid container md={9} sm={12} xs={12} className={classes.FD_FooterGrid} justify="flex-end">
                <Typography  className={classes.FD_lowerfooter}>   
                    POWERED BY
                    <img src={brandLogo} className={classes.FD_dee} alt="" /> 
                    <Typography  className={classes.FD_smallText} component="span" >DEENCONNECT</Typography>
                </Typography>
                </Grid>
               
               

            </Grid>
        </Grid>
      

    </Grid>
    );
}

export default LowerFooter;