const INITIAL_STATE = {

  user: {
    email: 'nadir@gmail.com',
    uid: 'abc',
    connactNumber: '03052013106'
  },
  userAsGuest: '',
  phoneNo: '',
  firstName: '',
  lastName: '',
  email: '',
  country: "",
  errorFlag: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, userName: action.payload }
    }
    case 'SET_USER_AS_Guest': {
      return { ...state, userAsGuest: action.payload, phoneNo: action.phoneNo }
    }
    case 'SET_FIRST_NAME': {
      return { ...state, firstName: action.firstName }
    }
    case 'SET_LAST_NAME': {
      return { ...state, lastName: action.lastName }
    }
    case 'SET_EMAIL': {
      return { ...state, email: action.email }
    }
    case 'SET_COUNTRY': {
      return { ...state, country: action.country }
    }
    case 'SET_ERROR': {
      return { ...state, errorFlag: action.errorFlag }
    }


    default:
      return state
  }
}
