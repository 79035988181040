import React from "react";
import escapeHtml from 'escape-html';
import { Node, Text } from 'slate';


// export const textSum = () => {
  
// }
// let sum = 0;
const serialize = node => {
  // console.log("serializeHtml", node)
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    // sum = sum + string.length
    // console.log(sum, string.length)

    if (node.bold) {
      string = `<strong>${string}</strong>`
    } else if(node.italic) {
      string = `<em>${string}</em>`
    } else if(node.underline) {
      string = `<u>${string}</u>`
    }

    return string
    // return escapeHtml(node.text)
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'heading-one':
      return `<h5>${children}</h5>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    default:
      return children
  }
}

function markdowntoreact(el) {
  return el.map((e) => {
    switch (e.type) {
      case "block-quote":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "paragraph":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-one":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-two":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "numbered-list":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <li>{em.text}</li>;
              }
              if (e.hasOwnProperty("bold")) {
                return <li>{em.text}</li>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      default:
        return null;
    }
  });
}

function markdowntoPlainHtml(el) {
  return el.map((e) => {
    switch (e.type) {
      case "block-quote":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "paragraph":
        let str = "";
        let str2 = e.children.map((em) => {
          if (em.hasOwnProperty("bold")) {
            let strB = em.text;
            str += strB;
            return str;
          }
          if(em.hasOwnProperty('italic')){
          str+=em.text
          return str
          }
          return (str += em.text);
        });
        return (
          <span dangerouslySetInnerHTML={{ __html: str2[str2.length - 1] }} />
        );
      case "heading-one":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-two":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      default:
        return null;
    }
  });
}

// const BLOCK_TAGS = {
//   blockquote: 'quote',
//   p: 'paragraph',
//   pre: 'code',
// }

// const MARK_TAGS = {
//   em: 'italic',
//   strong: 'bold',
//   u: 'underline',
// }

// export const rules = [
//   {
//     deserialize(el, next) {
//       const type = BLOCK_TAGS[el.tagName.toLowerCase()]
//       if (type) {
//         return {
//           object: 'block',
//           type: type,
//           data: {
//             className: el.getAttribute('class'),
//           },
//           nodes: next(el.childNodes),
//         }
//       }
//     },
//     serialize(obj, children) {
//       if (obj.object === 'block') {
//         switch (obj.type) {
//           case 'code':
//             return (
//               <pre>
//                 <code>{children}</code>
//               </pre>
//             )
//           case 'paragraph':
//             return <p className={obj.data.get('className')}>{children}</p>
//           case 'quote':
//             return <blockquote>{children}</blockquote>
//           default: 
//             return <p className={obj.data.get('className')}>{children}</p>
//         }
//       }
//     },
//   },
//   {
//     deserialize(el, next) {
//       const type = MARK_TAGS[el.tagName.toLowerCase()]
//       if (type) {
//         return {
//           object: 'mark',
//           type: type,
//           nodes: next(el.childNodes),
//         }
//       }
//     },
//     serialize(obj, children) {
//       if (obj.object === 'mark') {
//         switch (obj.type) {
//           case 'bold':
//             return <strong>{children}</strong>
//           case 'italic':
//             return <em>{children}</em>
//           case 'underline':
//             return <u>{children}</u>
//           default: 
//             return <p>{children}</p>
//         }
//       }
//     },
//   },
// ]


// const serialize = nodes => {
//   return nodes.map(n => Node.string(n)).join('\n')
// }

export { markdowntoreact, markdowntoPlainHtml,serialize };
