import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/DF_Logo.png';
import Avatar from '@material-ui/core/Avatar';
import personAvatar from '../../assets/img/imagedemo.jpg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
          height: "70px",
          background: "white",
          padding: "0px 120px",
        },
        [theme.breakpoints.down('sm')]: {
          padding: "0px 20px",
          background: "white",
        }
    },
    main: {
      flexFlow : "row wrap",
      alignItems: "stretch",
    },
    menus:{
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    appBar: {
      background: "#05CE78",
      },
    menuButton: {
      marginRight: theme.spacing(0),
    },
    logo: {
      position: "relative",
      // top: "15px"
    },
    personLogo: {
      position: "relative",
      top: "15px"
    },
    bellIcone: {
      padding: "0 40px 0 20px",
      marginTop: '25px'
    },
    moreIcone: {
      marginTop: '25px'
    },
    tabsStyles: {
        width:"auto",
        color: "rgba(0, 0, 0, 0.54)",
    },
    singleTab: {
      height: "58px",
      marginTop: "12px",
      minWidth: "auto",
      padding: "0px 24px 0px 24px",
    },
    bigIndicator: {
      height: 5,
      backgroundColor: "#05CE78"
    },
    activeTextColor: {
      color: "#05CE78"
    }
  }));


const Header = () =>  {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const classes = useStyles();
    return (
    <Grid container  className={classes.root} >
        <Grid container xs={12} direction="row" alignItems="stretch" sm={12} spacing={2} >
            <Grid container sm={12} justify="center" alignItems="center" className={classes.logo}>
                <img src={logo} alt="logo" />
            </Grid>
            {/* <Grid
            className={classes.menus}
            container 
            sm={3} 
            md={false}
            direction="row" 
            justify="flex-end"
            alignItems="stretch"> */}
                {/* <Tabs
                className={classes.tabsStyles}
                    value={value}
                    classes={{indicator: classes.bigIndicator}}
                    onChange={handleChange}
                    aria-label="tab"
                    
                    
                >
                    <Tab className={classes.singleTab+" "+(value === 0 ? classes.activeTextColor : '' )} label="Home" />
                    <Tab className={classes.singleTab+" "+(value === 1 ? classes.activeTextColor : '' )} label="About" />
                </Tabs> */}
                {/* <NotificationImportantOutlinedIcon className={classes.bellIcone} />
                <Avatar className={classes.personLogo}  alt="Remy Sharp" src={personAvatar} />
                <ExpandMoreIcon className={classes.moreIcone}  />
            </Grid> */}
            
        </Grid>

    </Grid>
    );
}

export default Header;