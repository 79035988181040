export function updateAmount (amount) {
  return dispatch => dispatch({ type: 'SET_AMOUNT', payload: amount })
}

export function updateDonationType (value) {
  return dispatch => dispatch({ type: 'SET_DONATIONTYPE', payload: value })
}

export function updateٖٖٖٖٖٖٖFee (value) {
  return dispatch => dispatch({ type: 'SET_FEE', payload: value })
}

export function categoryDonation (value, categoryId) {
  return dispatch => dispatch({ type: 'SET_CATEGORY', payload: value, id: categoryId })
}
export function addNote (value) {
  return dispatch => dispatch({ type: 'SET_NOTE', payload: value})
}
export function setOrgId (value) {
  return dispatch => dispatch({ type: 'SET_ORGANIZATION_ID', payload: value})
}
