import React, { Component } from 'react';

import AppBar from '../common/AppBar.js';
import Header from '../common/Header';
import Footer from '../common/footer';
import LowerFooter from '../common/lower-footer';
import Routes from './route';

class index extends Component {
    render() {
        return (
            <div>
                <AppBar />
                <Header />
                <Routes />
                {/* <Footer /> */}
                <LowerFooter />
            </div>
        )
    }
}

export default index;