import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Donor, Payment, Donation, Receipt, MacfeeLogo, StepHeader } from '../../components'
import verified_user from '../../assets/img/verified_user-24px.svg'
import './css/style.css'
import { updateSteps } from '../../store/Steps/actions.js'
import { updateOrg, updateOrgData } from '../../store/org/actions.js'
import { setOrgId } from '../../store/payment/actions.js'
import { organizations, getOrgId } from './apis'
import { Fragment } from 'react';
import {Helmet} from "react-helmet";

export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      categories: '',
      otp: '',
      paymentDone: false,
      shortName: '',
      paymentData: {},
      organization: '',
      customColor: '',
      metaData: {}
    }
    this.forNextStep = this.forNextStep.bind(this)
    this.finishPayment = this.finishPayment.bind(this)
  }

  async forNextStep(key, stateValue) {
    await this.setState({ [key]: stateValue })
  }

  finishPayment(data) {
    this.setState({
      paymentDone: true,
      paymentData: data
    })
    this.props.hideAlert()
  }

  componentDidMount() {
    this.getOrganizationsById()
  }

  async getOrganizationsById() {
    const pathname = window.location.pathname?.split("/")?.[1]
    console.log('pathname', pathname)
    try {
      const getId = await getOrgId(pathname)
      if (getId.status === 200) {
        const Id = getId?.data?.body?.data
        const orgRes = await organizations(Id)
        console.log("orgRes", orgRes)
        if (orgRes.status === 200) {
          const orgShortName = orgRes?.data?.body?.data?.orgShortName
          this.setState({ 
            shortName: orgShortName, 
            organization: orgRes?.data?.body?.data?.enabledIntegrations?.deenfund ? orgRes.data.body.data.enabledIntegrations.deenfund : {},
            // metaData: orgRes?.data?.body?.data?.enabledIntegrations?.deenfund?.embedForm?.[0].metaData ? orgRes.data.body.data.enabledIntegrations.deenfund.embedForm[0].metaData : {},
            metaData: orgRes?.data?.body?.data ? orgRes.data.body.data : {},
          })
          this.props.storeOrg(orgRes?.data?.body?.data?.enabledIntegrations?.deenfund ? orgRes.data.body.data.enabledIntegrations.deenfund : {})
          this.props.storeOrgData(orgRes?.data?.body?.data ? orgRes.data.body.data : {})
          this.props.setOrgId(Id)
        }
      }
    }
    catch (error) {
      if(error?.response?.data?.statusCode === 404){
        window.location.replace("http://shapla.deenfund.com/PageNotFound")
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.org !== prevState.org) {
      if (nextProps.org) {
        let themeColor = nextProps?.org?.org?.embedForm?.[0]?.formThemeColor ?? "#05CE78";
        document.body.style.setProperty('--text-color', themeColor)
        document.body.style.setProperty('--background-color', themeColor)
        document.body.style.setProperty('--border-color', themeColor)
      }
    }
    else return null;
  }

  render() {
    const { otp, paymentDone, shortName, paymentData, organization, metaData } = this.state
    const { page, payment } = this.props
    const { amount, donationType, fee } = payment
    const dfFee = organization && organization.platformFeeDiscount ? 0.0175 * (organization.platformFeeDiscount / 100) : 0.0175
    const calculation = amount >= 1 ? (fee ? (+amount + 0.3 + (+amount * dfFee)) / 0.971 : +amount) : 0
    const customColor = organization && organization.embedForm && organization.embedForm[0]?.formThemeColor
    const donationMinimumAmount = organization?.embedForm?.[0]?.donationMinimumAmount ? organization.embedForm[0].donationMinimumAmount : 1;

    let metaImage = organization?.embedForm?.[0]?.setup?.imageOrVideo === "image" && organization?.embedForm?.[0]?.setup?.content ? organization.embedForm[0].setup.content : metaData?.orgLogo?.logoThumbnail ? metaData.orgLogo.logoThumbnail : "";

    console.log("metaImage", metaImage)
    return (
      /* <div> */
      <div className={'donation-contianer'}>

        {/* META DATA */}

        <Helmet>
        {shortName && <title>{shortName?.toUpperCase()} - DEENFUND | Global donation & fundraising platform</title>}
        <meta name="description" content="DEENFUND is the most trusted & FREE online donation & crowdfunding platform to connect and empower global Muslim community. Give, care and support great causes for Goodness." />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta name="robots" content="index, follow" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={metaData?.orgName?.toUpperCase() ?? ""} />
        <meta property="og:title" content={metaData?.orgName ? `${metaData?.orgName?.toUpperCase()}` : ""} />
        <meta property="og:description" content="DEENFUND is the most trusted & FREE online donation & crowdfunding platform to connect and empower global Muslim community. Give, care and support great causes for Goodness." />
        <meta property="og:image" content={metaImage ?? ""} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={metaData?.orgWebsite ?? ""} />
        <meta property="og:see_also" content={metaData?.orgWebsite ?? ""} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={""} />
        <meta name="twitter:title" content={metaData?.orgName ? `${metaData?.orgName?.toUpperCase()}` : ""} />
        <meta name="twitter:description" content="DEENFUND is the most trusted & FREE online donation & crowdfunding platform to connect and empower global Muslim community. Give, care and support great causes for Goodness." />

        <meta name="twitter:image" content={metaImage ?? ""} />

        </Helmet>


        <div className='donation-card'>
          <div className='bg-donation'>
            {!paymentDone ? <>
              {/* <Notification /> */}
              <p className='headText'>{organization && organization.embedForm[0] && organization.embedForm[0].formTitle}</p>
              <StepHeader
                count='1'
                title='Donation details'
              />

              <Donation
                homeNav={this.props.homeNav}
                page={page}
                forNextStep={this.forNextStep}
              />
              <br />
              <StepHeader
                count='2'
                title='DONOR'
                verified={otp === 'OTP Verified' && <img src={verified_user} width='24px' height='24px' style={{ marginRight: '4%', marginTop: '-5px' }} />}
                verifiedText={otp === 'OTP Verified' && 'VERIFIED'}
              />

              <Donor forNextStep={this.forNextStep} showAlert={this.props.showAlert} />

              <br />

              <Fragment>
                <StepHeader
                  count='3'
                  title='PAYMENT'
                  payment={amount >= parseInt(donationMinimumAmount) ? `TOTAL charge ${'$' + (amount >= parseInt(donationMinimumAmount) ? calculation.toFixed(2) : 0.00)}/${donationType}` : `TOTAL charge $0.00/${donationType}`}
                />

                <Payment forNextStep={this.forNextStep} finishPayment={this.finishPayment} verified={otp === 'OTP Verified'} showAlert={this.props.showAlert} hideAlert={this.props.hideAlert} />
              </Fragment>
            </>
              : <Receipt
                forNextStep={this.forNextStep}
                onClick={() => window.location.reload()}
                hideAlert={this.props.hideAlert}
                totalCharges={'$' + calculation.toFixed(2)}
                frequency={donationType}
                shortName={shortName}
                paymentData={paymentData}
                customColor={customColor}
              />}
          </div>
        </div>

      </div>

      /* </div> */
    )
  }
}

function mapStateToProps(state) {
  return {
    steps: state.steps,
    payment: state.payment,
    org: state.org
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeSteps: (payload) => dispatch(updateSteps(payload)),
    storeOrg: (payload) => dispatch(updateOrg(payload)),
    storeOrgData: (payload) => dispatch(updateOrgData(payload)),
    setOrgId: (payload) => dispatch(setOrgId(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
