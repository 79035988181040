const INITIAL_STATE = {
  org: '',
  orgData: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ORG': {
      return { ...state, org: action.payload }
    }

    case 'SET_ORG_DATA': {
      return { ...state, orgData: action.payload }
    }

    default:
      return state
  }
}
