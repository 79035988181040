import React, { Component } from 'react'
import { Button } from '../../components'
import imgtick from '.././../assets/rawimg/success_icon.png'
import Grid from '@material-ui/core/Grid'
import './css/Receipt.css'
import { connect } from 'react-redux'
import {updateSideBarFlag} from '../../store/Steps/actions.js'

class Receipt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: ''
    }

  }

  componentDidMount() {
    const {updateSideBarFlag} = this.props
    updateSideBarFlag(false)
    this.props.hideAlert();
  }

  render() {
    const { width } = this.state
    const { totalCharges, frequency, shortName, paymentData } = this.props  
  return (
      <>
        <div className="succcess_page"
          style={{
            display: 'flex',
            justifyContent: 'cente',
            flexDirection: 'column',

          }}
        >
          <div style={{ width: '100%', alignSelf: 'center' }}>
            <center >
              <p
                style={{
                  // color: '#545268',
                  fontFamily: 'Roboto-Regular',
                  fontSize: '24px'
                }}
              >
                <img src={imgtick} width='108px' height='108px' />
                {/* <p className='right_col_title'>Donation Successful</p> */}
                <p className='Donation-Successful' style={{
                  padding: 0,
                  marginBottom: 8,
                  letterSpacing: 0.24,
                  // color: '#1A1A1A'
                }}
                >Donation Successful
                </p>
              </p>



              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p style={{ color: '#747474', fontSize: '16px', fontFamily: 'Roboto-Regular', lineHeight: '24px' }}>
                  <p>We greatly appreciate your generous</p>
              {' '}<span style={{ textTransform: 'lowercase' }}>{frequency}</span> donation of {totalCharges} to {shortName}
                   
                  <div className="don_number">
                    {paymentData && paymentData.receipt_number &&  `Your donation confirmation number is`}
                    <span className="don_number_ref"> {paymentData && paymentData.receipt_number && paymentData.receipt_number} </span>
                  </div>

                  <div className="jazak_Allah">
                    Thank You
              </div>
                </p>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="success_border_line"></div>
                  <div className="submit_btn btn_donate" style={{ textAlign: 'center' }}>
                    <Button
                      // disabled={!isValid}
                      onClick={() => this.props.onClick()} // this.stripTokenApiHit()
                      title='Donate more'
                      widthHeight={!width ? [510, 60] : [470, 60]}
                      // icon={<LockIcon />}
                      className="checkout"
                      customColor={this.props.customColor}
                    />
                  </div>
                </Grid>
              </Grid>


              {/* <p style={{ color: '#818E94', fontSize: '16px', fontFamily: 'Roboto-Regular', lineHeight: '33px' }}>
              Your donation confirmation number is "confirmationNum" <br/> Jazak-Allahu Khayran
              </p>

              { <div className="donate_more_btn" style={{ marginTop: '50px' }}>
                <Button
                  title='Donate more'
                  style={{ fontSize: '16px' }}
                  widthHeight={[300, 60]}
                  onClick={this.props.onClick}
                />
              </div> } */}

            </center>
          </div>
        </div>

      </>
    )
  }
}


function mapDispatchToProps(dispatch) {
  return {
    updateSideBarFlag: (payload) => dispatch(updateSideBarFlag(payload)),
  }
}

export default connect(null, mapDispatchToProps)(Receipt)


