import React, { Component } from "react";
import { Expand } from "../../components";
import ReadMoreAndLess from "react-read-more-less";
import { connect } from "react-redux";
import "../../screens/Donation/style.css";
import { markdowntoreact, serialize } from "../../utils/markdowntoreact";
import { Fragment } from "react";
// import Html from 'slate-html-serializer'

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goBack: false,
      showAlert: false,
      type: "",
      image_or_video: null,
      faq: [],
      readMore: "",
      content: "",
      descriptionLength: 0,
      readMoreBtn: true
    };

    this.showAlert = this.showAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    // this.goBack = this.goBack.bind(this);
  }

  showAlert(type) {
    this.setState({
      showAlert: true,
      type: type,
    });
  }

  hideAlert() {
    this.setState({
      showAlert: false,
    });
  }

  descriptionSum(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });

    // this.setState({
    //   descriptionLength: sum,
    // });
    // console.log(sum, "sum")

    return sum;
  };

  

  // goBack() {
  //   this.setState({ goBack: true }, () => {
  //     this.setState({goBack: false});
  //   });
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    let image_or_video;
    let content;
    let faq;
    let readMore;

    if (nextProps.org !== prevState.org) {
      if (nextProps.org) {
        let Embed_form_rightSide =
          nextProps.org.org &&
          nextProps.org.org.embedForm[0] &&
          nextProps.org.org.embedForm[0];

        image_or_video =
          Embed_form_rightSide &&
          Embed_form_rightSide.setup &&
          Embed_form_rightSide.setup.imageOrVideo &&
          Embed_form_rightSide.setup.imageOrVideo;
        content =
          Embed_form_rightSide &&
          Embed_form_rightSide.setup &&
          Embed_form_rightSide.setup.content &&
          Embed_form_rightSide.setup.content;
        faq =
          Embed_form_rightSide &&
          Embed_form_rightSide.setup &&
          Embed_form_rightSide.setup.faqs &&
          Embed_form_rightSide.setup.faqs;
        readMore =
          Embed_form_rightSide &&
          Embed_form_rightSide.setup &&
          Embed_form_rightSide.setup.rightSide &&
          Embed_form_rightSide.setup.rightSide;
      }
      return {
        image_or_video,
        faq,
        readMore,
        content,
      };
    } else return null;
  }

  render() {
    const {
      goBack,
      showAlert,
      type,
      readMoreBtn,
      image_or_video,
      faq,
      readMore,
      content,
    } = this.state;



    let x = readMore && JSON.parse(readMore);
    let editor, sum = 0;
    if(x) {
     sum = this.descriptionSum(x);
     editor = {
        children: [
          ...x
        ]
      }
    }


    // console.log("readMore", readMore && serialize(editor))
    const parseReadMore = readMore && readMore.includes('"type":"paragraph"') ? serialize(editor) : readMore;

    function isValidImageURL(str) {
      if (typeof str !== "string") return false;
      return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
    }
    return (
      <>
        <div className="side-section-container">
          {content && (
            <div className="video-image-container">
              {/* {isValidImageURL(img_video_props) ? */}
              {image_or_video === "image" ? (
                <img className="side-bar-img" src={content} width="100%" />
              ) : (
                <iframe
                  style={{
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={`${content && content}?rel=0`}
                  frameBorder="0"
                  allowFullScreen="allowFullScreen"
                />
              )}
            </div>
          )}
          <div className="message-container">
            <p className="message-side-bar-text">
              {/* <ReadMoreAndLess
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={250}
                readMoreText="Read more"
                readLessText="Read less"
              > */}

                {parseReadMore && sum > 240 ? <Fragment>
                  {readMoreBtn ? <><div dangerouslySetInnerHTML={{ __html: parseReadMore.substring(0, 240) }}></div>
                  <p className="readMoreText" onClick={() => this.setState({readMoreBtn: false})}>Read more</p></> : <><div dangerouslySetInnerHTML={{ __html: parseReadMore }}></div>
                  <p className="readMoreText" onClick={() => this.setState({readMoreBtn: true})}>Read less</p></>}
                  
                </Fragment> : <Fragment>
                  <div dangerouslySetInnerHTML={{ __html: parseReadMore }}></div>
                </Fragment>}

                {/* {parseReadMore.substring(1, 4);} */}
                {/* {`${parseReadMore && parseReadMore.length > 0 ? parseReadMore : "..."}`} */}
              {/* </ReadMoreAndLess> */}
            </p>
          </div>
          <h1 className="got-questions">Questions?</h1>
          <div className="Accordian">
            <Expand faq={faq} />
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    sideBarflag: state.steps.sideBarflag,
    org: state.org,
  };
}

export default connect(mapStateToProps, null)(SideBar);
