export function updateUser () {
  return dispatch => dispatch({ type: 'SET_USER', payload: 'Ali' })
}

export function updateUserAsGuest (userAsGuest, phoneNumber) {
  return dispatch => dispatch({ type: 'SET_USER_AS_Guest', payload: userAsGuest, phoneNo: phoneNumber })
}
export function updateFirstName (firstName) {
  return dispatch => dispatch({ type: 'SET_FIRST_NAME', firstName: firstName })
}
export function updateLastName (lastName) {
  return dispatch => dispatch({ type: 'SET_LAST_NAME', lastName: lastName })
}

export function updateEmail (email) {
  return dispatch => dispatch({ type: 'SET_EMAIL', email: email })
}

export function updateCountry (country) {
  return dispatch => dispatch({ type: 'SET_COUNTRY', country: country })
}

export function updateErrorFlag (errorFlag) {
  return dispatch => dispatch({ type: 'SET_ERROR', errorFlag: errorFlag })
}
