import React from 'react';
import {BrowserRouter as Router , Route,  withRouter} from 'react-router-dom';
 
import PrivacyPolicy from '../PrivacyPolicy';
import TermsPolicies from '../TermsPolicies';
import AboutUs from '../AboutUs';


class Routes extends React.Component {

	render(){
		return (
			<Router  >
			
				<Route  
					exact 
					path={"/privacy-policy"} 
					component={
						() => <PrivacyPolicy />
					}
				/>
				<Route  
					exact 
					path={"/terms-policies"} 
					component={
						() => <TermsPolicies />
					}
				/>

			<Route  
					exact 
					path={"/about-us"} 
					component={
						() => <AboutUs />
					}
				/>
			</Router>
		);
	}
}

export default withRouter(Routes);