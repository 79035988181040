import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'
import { updateAmount, updateDonationType, updateٖٖٖٖٖٖٖFee, categoryDonation, addNote } from '../../store/payment/actions'
import LockIcon from '@material-ui/icons/Lock'
import { editFeat, updateSideBarFlag } from '../../store/Steps/actions.js'
import swal from 'sweetalert2'
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded'
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import { Button, OutlinedInput, MacfeeLogo, SelectInput, Input, Checkbox } from '../../components'
import { donationCategory, donationAmount } from '../../json'
import { organizations, getOrgId, getSelectedDonationCategories } from './apis'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import './css/style.css'

class Donation extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isProceed: true,
      title: 'Select donation amount',
      amountActive: 100,
      omBtnAvtive: 'Monthly',
      check: false,
      categories: [],
      addNote: false,
      note: '',
      length: 0,
      donationErrorText: 'Enter min of $1',
      isDonationError: false,
      amountInput: '',
      otherEmtyField: true,
      otherAmountValue: '',
      defaultValueCat: '',
      defaultKeyCat: '',
      org: "",
      amounts: [],
      minimumAmount: 1
    }
    this.proceesNext = this.proceesNext.bind(this)
    this.handleEditStep = this.handleEditStep.bind(this)
    this.addNote = this.addNote.bind(this)
    this.donationErrorhandle = this.donationErrorhandle.bind(this)
  }

  async componentDidMount() {
    const { updateSideBarFlag } = this.props
    const pathname = window.location.pathname.split("/")[1]
    const getId = await getOrgId(pathname)
    if (getId.status === 200) {
      const Id = getId?.data?.body?.data
      const orgRes = await organizations(Id)
      if (orgRes.status === 200) {
        const org = orgRes?.data?.body?.data
        const orgSelectedCategories = await getSelectedDonationCategories(org?._id)
        console.log("orgSelectedCategories", orgSelectedCategories)
        this.getCategories(orgSelectedCategories?.data?.body?.data)
        this.getDonationAmount(org)
      }
    }
    updateSideBarFlag(true)
    this.props.storeAmount(this.state.amountActive)
  }

  async getDonationAmount(org) {
    org = org?.enabledIntegrations?.deenfund;
    const preselect_amounts = org?.embedForm?.[0]?.preselectAmounts
    const minimumAmount = org?.embedForm?.[0]?.donationMinimumAmount === 'default' ? 5 : org?.embedForm?.[0]?.donationMinimumAmount
    console.log("minimumAmount", minimumAmount, preselect_amounts);
    let amountActive = 100
    const amounts = preselect_amounts && preselect_amounts.map((item) => {
      if (item.setDefault) {
        amountActive = item.amount
      }
      return item.amount
    })
    this.setState({
      amountActive: amounts?.[0] ? amountActive : 100,
      amounts: amounts?.[0] ? [{ value: amounts }] : donationAmount,
      minimumAmount, 
      donationErrorText: `Enter min of $${minimumAmount ? minimumAmount : 1}`,
    })
  }

  async getCategories(orgSelectedCategories) {
    const { storeCategory } = this.props
    let categories = []
    await this.props.forNextStep('categories', categories)
    const list = orgSelectedCategories && orgSelectedCategories.length ? orgSelectedCategories : []
    const list2 = list && list?.filter(category => category.status !== 'Inactive')
    if (list2 && list2.length) {
      list2.map(item => {
        const categoryItem = categories[categories.length - 1]
        if (categoryItem && categoryItem.value.length < 2) {
          categoryItem.value.push(item.name)
          categoryItem.key.push(item._id)
        } else {
          categories.push({ value: [item.name], key: [item._id] })
        }
      })
    }
    // } else {
    //   categories = donationCategory
    // }
    this.setState({
      categories,
      loading: false,
      defaultValueCat: categories?.[0]?.value?.[0],
      defaultKeyCat: categories?.[0]?.key?.[0]
    },
      () => {
        storeCategory(categories?.[0]?.value?.[0], categories?.[0]?.key?.[0])
      })
  }

  proceesNext() {
    const { amount } = this.props
    if (amount) {
      this.setState({
        isProceed: false,
        title: 'Select Donation Category',
        alert: false
      })
    } else {
      this.setState({ alert: true })
      swal.fire({
        icon: 'error',
        text: 'Please select amount',
        timer: 3000,
        showConfirmButton: false
      })
    }
  };

  handleEditStep() {
    this.props.editFeat({ flag: false, step: 2 })
  }

  addNote(e) {
    if (e.target.value.length > 50) {
      return false
    }
    else {

      this.setState({
        note: e.target.value,
        length: e.target.value.length
      })

      this.props.addNote(e.target.value)
    }
  }

  componentDidUpdate() {
    const { omBtnAvtive, check } = this.state
    const { storeDonationType, storeFee } = this.props
    storeDonationType(omBtnAvtive)
    storeFee(check)
  }

  donationErrorhandle(value, other) {
    const { storeAmount } = this.props
    const { minimumAmount } = this.state;
    if (other) {
      if (Number(value) >= Number(minimumAmount ? minimumAmount : 1)) {
        this.setState({ isDonationError: false })
      } else {
        this.setState({ isDonationError: true })
      }
    }

    storeAmount(value)
  }

  render() {
    const { otherAmountValue, amounts, defaultValueCat, defaultKeyCat, isProceed, otherEmtyField, title, amountActive, omBtnAvtive, check, categories, length, addNote, donationErrorText, isDonationError, amountInput } = this.state
    const { amount, storeAmount, storeCategory, note, errorFlag, org, orgData } = this.props
    const { flag } = this.props.editFlag
    const customColor = org?.embedForm && org.embedForm?.[0]?.formThemeColor
    const btnStyle = {
      fontSize: '20px',
      color: '#1A1A1A',
      fontFamily: 'Roboto-Regular',
      padding: 0
    }
    const btnActive = {
      fontSize: '20px',
      fontFamily: 'Roboto-Regular',
      background: customColor ? customColor : '#05CE78',
      color: '#fff',
      padding: 0

    }
    const donationCatStyle = {
      normal: {
        fontSize: '18px',
        color: '#000',
        fontFamily: 'Roboto-Regular',
        textTransform: 'capitalize'
      },
      active: {
        fontSize: '18px',
        color: '#05CE78',
        fontFamily: 'Roboto-Regular',
        textTransform: 'capitalize',
        backgroundColor: customColor ? customColor : '#05CE78'
      },
      focus: {
        fontSize: '18px',
        fontFamily: 'Roboto-Regular',
        background: '#e5faf1',
        color: '#05CE78'
      }
    }

    const onTimeMontlybtn = {
      normal: {
        borderRadius: '0px',
        margin: '0px',
        fontSize: '14px',
        fontFamily: 'Roboto-Medium',
        textTransform: 'capitalize',
        letterSpacing: '1.26px',
        marginTop: 5,
        textTransform: 'uppercase',
        color: '#1A1A1A'


      },
      active: {
        borderRadius: '0px',
        margin: '0px',
        fontSize: '14px',
        fontFamily: 'Roboto-Medium',
        textTransform: 'capitalize',
        background: customColor ? customColor : '#05CE78',
        color: '#fff',
        letterSpacing: '1.26px',
        marginTop: 5,
        textTransform: 'uppercase'


      }
    }
    return (
      <>
        <div>
          <div>
            {/* <div className='right_col_title'>{title} </div>  */}

            <div className='dropDown'>
              <SelectInput
                defaultValue={defaultValueCat}
                defaultKey={defaultKeyCat}
                autoFocus={true}
                variant="outlined"
                onChange={(e) => {
                  const catVauleWithId = e.target.value
                  const categoryArray = catVauleWithId.split(',')
                  const categoryValue = categoryArray[0]
                  const categoryId = categoryArray[1]
                  storeCategory(categoryValue, categoryId)
                  this.setState({ categorySelected: true, categoryId })
                }}
                customColor={customColor ?? '#05CE78'}
                label='Select Donation Category'
                minWidth='85%'
                option={
                  categories.map((items, index) => {
                    return (
                      <>
                        {(items.key[0] !== defaultKeyCat) && <option key={items.value[0] + 'category'} value={`${items.value[0] + ',' + items.key[0]}`}>{items.value[0]}</option>}
                        {
                          items.value[1] &&
                          <option key={items.value[1] + 'category'} value={`${items.value[1] + ',' + items.key[1]}`}>{items.value[1]}</option>
                        }
                      </>
                    )
                  })
                }
              // error={!this.state.categoryId && this.props.errorFlag}
              />
            </div>
            {isProceed ? (
              <center>
                <>
                  {amounts && amounts.map((items, index) => {
                    return (
                      <div key={index + '-donation'} className='btnRow donation_btn'>
                        <div className='donate_button'>
                          {items.value.map((itemValues, index) => {
                            return (
                              <Button
                                className="btn_hover"
                                title={'$' + itemValues}
                                customColor={customColor ?? '#05CE78'}
                                onClick={() => {
                                  this.donationErrorhandle(itemValues)
                                  this.setState({ amountActive: itemValues, amountInput: false, otherAmountValue: '', isDonationError: false })
                                }}
                                widthHeight={[127, 56]}
                                style={!amountInput && amountActive === itemValues || !amountInput && amount === itemValues ? btnActive : btnStyle}
                                variant='outlined'
                                key={Math.random() * 2}
                                customBorder={!amountInput && amountActive === itemValues && (customColor ? `1px solid ${customColor}` : '1px solid #05CE78')}
                              />

                            )
                          })}
                        </div>

                        <div className='amountText'>
                          <OutlinedInput
                            maxlength="6"
                            value={amountInput ? amount : ''}
                            customBorder={isDonationError ? '2px solid #FF5353' : amountInput && (customColor ? `2px solid ${customColor}` : '1px solid #05CE78')}
                            onClick={() => {
                              this.setState({ amountInput: true }, 
                              !amountInput ? () => storeAmount('') : null)
                            }}
                            leftText={amountInput && '$'}
                            placeholder={!amountInput ? "Other" : ""}
                            onChange={(e) => {
                              if ((e.target.value).indexOf('.') === -1 && e.target.value.length <= 6 && e.target.value <= 899999) {
                                // this.setState({
                                //   otherAmountValue: e.target.value
                                // })
                                this.donationErrorhandle(e.target.value, true)
                              }
                            }}
                            errorText={(isDonationError && donationErrorText || errorFlag && !amount && 'Please fill in this field')}
                          />
                        </div>

                      </div>
                    )
                  })}

                  <div className='om-btn' style={isDonationError && donationErrorText ? {marginTop: "32px"} : {} }>
                    <Button
                      className="btn_hover"
                      title='ONE TIME'
                      customColor={customColor ?? '#05CE78'}
                      widthHeight={[340, 52]}
                      variant='outlined'
                      style={omBtnAvtive === 'One Time' ? onTimeMontlybtn.active : onTimeMontlybtn.normal}
                      onClick={() => this.setState({ omBtnAvtive: 'One Time' })}
                      customBorder={omBtnAvtive === 'One Time' && (customColor ? `1px solid ${customColor}` : '1px solid #05CE78')}
                      icon={<i class='fas fa-hand-holding-heart' />}
                    />
                    <Button
                      style={omBtnAvtive === 'Monthly' ? onTimeMontlybtn.active : onTimeMontlybtn.normal}
                      title='MONTHLY'
                      className="btn_hover"
                      customColor={customColor ?? '#05CE78'}
                      widthHeight={[340, 52]}
                      variant='outlined'
                      onClick={() => this.setState({ omBtnAvtive: 'Monthly' })}
                      customBorder={omBtnAvtive === 'Monthly' && (customColor ? `1px solid ${customColor}` : '1px solid #05CE78')}
                      icon={<i class='fas fa-sync' />}

                    />
                  </div>
                  {org?.embedForm?.[0]?.processingFeeEnabled && <div className='btns' >
                    <Checkbox
                      checked={check}
                      onClick={() => this.setState({ check: !check })}
                    />
                    <div className='check-box'>
                      {/* {
                      check && <CheckBoxRoundedIcon onClick={() => this.setState({ check: false })} style={{ position: 'absolute', color: '#7266FB', fontSize: 26, margin: '-3px 0 0 16px' }} />
                    }{
                      !check && <CheckBoxOutlineBlankRoundedIcon onClick={() => this.setState({ check: true })} style={{ position: 'absolute', color: '#7266FB', fontSize: 26, margin: '-3px 0 0 16px' }} />
                    } */}
                      {orgData && orgData.orgShortName && <span onClick={() => this.setState({ check: !check })} className='custom-control-label checkbox-text'>
                      Please keep my donation anonymous <Tooltip title="Your name will only be visible to the organization and internal team members." placement="top"><InfoRoundedIcon className='tooltipIcon'/></Tooltip> 
                    </span>}

                    </div>

                    

                  </div>}


                  {org?.embedForm?.[0]?.processingFeeEnabled && <div className='btns'>
                    <Checkbox
                      checked={check}
                      onClick={() => this.setState({ check: !check })}
                    />
                    <div className='check-box'>
                      {/* {
                      check && <CheckBoxRoundedIcon onClick={() => this.setState({ check: false })} style={{ position: 'absolute', color: '#7266FB', fontSize: 26, margin: '-3px 0 0 16px' }} />
                    }{
                      !check && <CheckBoxOutlineBlankRoundedIcon onClick={() => this.setState({ check: true })} style={{ position: 'absolute', color: '#7266FB', fontSize: 26, margin: '-3px 0 0 16px' }} />
                    } */}
                      {orgData && orgData.orgShortName && <span onClick={() => this.setState({ check: !check })} className='custom-control-label checkbox-text'>
                        Add processing fees so that 100% of my donation goes directly to {orgData.orgShortName}.
                    </span>}

                    </div>

                    

                  </div>}




                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className='note-div'>
                      {!addNote ? <a className='note' onClick={() => this.setState({ addNote: true })}>Add Note (Optional)</a>
                        : <div className='input-field'>
                          <div className='note-input not_field_label'>

                            <TextField
                              style={{ width: '100%', display: 'flex' }}
                              variant="outlined"
                              placeholder='Write your message here'
                              onChange={(e) => {
                                this.addNote(e)
                              }}
                              value={note}
                              type='text'
                            />
                            <p className='char'>{`CHAR ${note.length}/50`}</p>
                          </div>
                          <div className='cancel' onClick={() => {
                            this.setState({ addNote: false, note: '' })
                            this.props.addNote('')
                          }}>
                            <CancelIcon />
                          </div>

                        </div>}
                    </div>
                  </Grid>
                </>
              </center>
            ) : (
                <>
                  {/* {categories.map((items, index) => {
                  return (
                    <div key={items + '-donation'} className='btnRow'>
                      <Button
                        title={items.value[0]}
                        widthHeight={[250, 90]}
                        style={donationCatStyle.normal}
                        variant='outlined'
                        onClick={() => {
                          storeCategory(items.value[0], items.key[0])
                          this.props.forNextStep('categories', categories)
                        }}
                      />
                      {
                        items.value[1] &&
                          <Button
                            title={items.value[1]}
                            widthHeight={[250, 90]}
                            style={donationCatStyle.normal}
                            variant='outlined'
                            onClick={() => {
                              storeCategory(items.value[1], items.key[1])
                              flag
                                ? this.handleEditStep()
                                : this.props.forNextStep('categories', categories)
                            }}

                          />
                      }
                    </div>
                  )
                })} */}
                </>
              )}
            {/* {isProceed && (
              <Button
                title='PROCEED NEXT'
                onClick={
                  this.props.page === 'home'
                    ? () => {
                      if (amount !== '') {
                        this.setState({ alert: false })
                        this.props.homeNav.history.push('./donation')
                        flag ? this.handleEditStep() : this.proceesNext()
                      } else {
                        this.setState({ alert: true })
                      }
                    } : () => {
                      flag ? this.handleEditStep(): this.proceesNext()
                    }
                }
                widthHeight={[510, 60]}
                icon={<LockIcon />}
              />
            )} */}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    amount: state.payment.amount,
    donationType: state.payment.donationType,
    fee: state.payment.fee,
    editFlag: state.steps,
    note: state.payment.note,
    errorFlag: state.users.errorFlag,
    org: state.org.org,
    orgData: state.org.orgData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeAmount: (payload) => dispatch(updateAmount(payload)),
    storeDonationType: (payload) => dispatch(updateDonationType(payload)),
    storeFee: (payload) => dispatch(updateٖٖٖٖٖٖٖFee(payload)),
    storeCategory: (payload, categoryId) => dispatch(categoryDonation(payload, categoryId)),
    editFeat: (payload) => dispatch(editFeat(payload)),
    addNote: (payload) => dispatch(addNote(payload)),
    updateSideBarFlag: (payload) => dispatch(updateSideBarFlag(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Donation)
