import React from 'react';
import './style.css';

export default function StepHeader(props) {
  return (
    <>
      <div className='step-hearder'>
        <p className="head">
          <>
            <span style={{ color: '#747474' }}>STEP {props.count} -</span>{' '}
            <span style={{ color: '#4D4D4D' }}>{props.title}</span>
          </>
         <span  style={{ color: '#6C8FA4', float: 'right', marginRight: '', display: 'flex' }}>{props.verified} {props.verifiedText}</span>
          <span className="total_amount" style={{ color: '#747474', float: 'right', marginRight: '' }}>{props.payment}</span>
        </p>
      </div>
    </>
  );
}
