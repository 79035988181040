import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

export default function NativeSelects (props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: props.minWidth || 130
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    htext: {
      display: 'flex',
      fontFamily: 'Roboto-Regular',
      fontSize: '14px',
      textIndent: '5px',
      color: 'red',
      paddingTop: 5
    }
  }))


  const classes = useStyles()
  const customColor = props.customColor

  return (
    <div>
      <FormControl error={props.error} variant={props.variant} className={classes.formControl}>
        <InputLabel htmlFor='outlined-age-native-simple'>
          <p style={{display: 'inline'}}>{props.label}</p>
          </InputLabel>
        <Select
        className='select-border'
          native
          defaultValue={props.defaultValue}
          autoFocus={props.autoFocus}
          onChange={props.onChange}
          label={props.label}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple'
          }}
        >
        {/* <option aria-label='None' key={props.defaultKey} value={props.defaultValue}>{props.defaultValue}</option> */}
        <option key={props.defaultValue + 'category0'} value={`${props.defaultValue + ',' + props.defaultKey}`}>{props.defaultValue}</option>
          {props.option}
        </Select>
        {/* {!props.error && <span className={classes.htext}>{props.helperText}</span>} */}
        {/* {props.error && <span className={classes.htext}>Please select donation category</span>} */}
      </FormControl>
    </div>
  )
}
