import axios from 'axios'

const baserUrl =
  'https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000'

export function verification (body) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baserUrl}/auth/verify`, body)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const dfBaseUrl = 'https://cors-anywhere.herokuapp.com/https://2w0watsk73.execute-api.us-east-1.amazonaws.com/dev'

export function generateOtp (body) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${dfBaseUrl}/otp/generateOTP`, body)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function phoneVerification (body) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${dfBaseUrl}/otp/verifyOTP`, body)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
