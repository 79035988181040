

import React, { useEffect, Component } from 'react'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '../../assets/img/Done.svg'

import './style.css'

export default class SimpleAlerts extends Component {
  state = {
    open: true,
    show: false
  }


  componentDidMount() {
    if (this.props.show) {
      this.setState({ show: true })
    }
  }

  render() {
    const { show, type } = this.props;
    const alertContent = (
      this.state.open && 
      
      <div className={(type === 'success' || type === 'invited') ? 'main' : 'error-main'}>

        <div className={(type === 'success' || type === 'invited') ? 'alert' : 'error-alert'}>
          <Collapse in={this.state.open}>
            <Alert
              severity={type}
              action={
                <IconButton
                  aria-label='close'
                  size='small'
                  color="red"
                  onClick={() => {
                    this.setState({ open: false })
                  }}
                  className="icon-close"
                >
                  
                  <div>
                      <CloseIcon fontSize='inherit' style={{ color: '#FF5353' , marginRight: 5 }} className="close" />
                  </div>

                </IconButton>
              }
              className="alertMsg"
            >
                <img src={DoneIcon} />
              {type === 'invited' ? "Invitation accepted successfully!" : type === 'success' ? 'We have sent a text message to your mobile with a 6-digit security code.' : 'Payment validation failed.  Please re-enter valid credit card details.'}
              
            </Alert>
          </Collapse>
        </div>

      </div>

    )
    return (
      show ? 
        alertContent : ''
    )
  }

}
