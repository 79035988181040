import React from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/footer_Logo.png';
import Typography from '@material-ui/core/Typography';
import facebookIcon from '../../assets/img/facebook.png';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';




const useStyles = makeStyles((theme) => ({
    footer: {
        borderBottom: "1px solid #0000001A",
        background: "#f7f9fa",
        padding: "24px",
    },
    FD_footerInner:{
        maxWidth: "1140px",
        margin: "0 auto"
    },
    logoSection: {
        padding: '8px'
    },
    FD_footerlogoText : {
        color: "#4C5E67",
        fontSize: "14px",
        lineHeight: "1.5",
        margin: "14px 0" 
    },
    FD_footer_column1: {
        textAlign: "center",
        margin: "16px auto",
        [theme.breakpoints.down('xs')]: {
            alignItems: "flex-start",
            margin: "0px"
          }
 
    },
    FD_footer_column2:{
        margin: "16px auto",
        padding: "0px",
        [theme.breakpoints.down('sm')]: {
            alignItems: "flex-start",
            margin: "0px"
          }
    },
    FD_footer_title: {
        color: "#294C5C",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    FD_footer_li: {
        color: "#4C5E67",
        opacity: "0.8",
        fontSize: "14px",
        padding: '6px 0'
    },
    FD_footer_ul: {
        fontSize: "14px",
        margin: "16px 0px 16px 0px",
        padding: "0px"
    },
    FD_footer_text: {
        margin: "0px"
    },
    FD_social_img_tag_fb: {
        background: "#666",
        position: "relative",
        padding: "5px 11px",
        marginTop: "16px",
        borderRadius: "32px",
    },
    social_img_tag_twitter: {
        padding: "5px",
        border: "1px solid #666",
        borderRadius: "33px",
        background: "transparent !important",
        marginTop: "16px",
        height: "20px",
        width: "20px",
        color: "#666",
        marginLeft: "5px"
    }
    }));


const Footer = () =>  {

    const classes = useStyles();
    return (
    <Grid container  className={classes.footer} >
        <Grid container className={classes.FD_footerInner} >
            <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                
                <Grid item md={3} sm={6} xs={12} className={classes.logoSection}  >
                    <div>
                    <img src={logo} alt="logo" />
                    </div>
                    <Typography className={classes.FD_footerlogoText} variant="body2">
                        DEENFUND Is A Global Platform Connecting Awesome Donors With Amazing Organizations With Great Causes.
                    </Typography>
                </Grid>
                <Grid container md={3} sm={6} xs={12} alignItems="flex-start" justify="flex-start" direction="column" >
                    <Grid item className={classes.FD_footer_column1}>
                    <Typography  className={classes.FD_footer_title}>
                    DISCOVER
                    </Typography>
                    <List className={classes.FD_footer_ul} >
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Home" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="About Us" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Features" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Pricing" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Faq's" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Contact Us" /></ListItem>
                    </List>
                    </Grid>
                </Grid>
                <Grid container md={3} sm={6} xs={12} alignItems="flex-start" justify="flex-start" direction="column" >
                    <Grid item className={classes.FD_footer_column2}>
                    <Typography className={classes.FD_footer_title}>
                    DISCOVER
                    </Typography>
                    <List className={classes.FD_footer_ul} >
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="How it Works For Donors" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="How it Works For Orgs" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Make Donations" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Add Organization" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Org Secured Login" /></ListItem>
                        <ListItem className={classes.FD_footer_li}><ListItemText className={classes.FD_footer_text} secondary="Make donations" /></ListItem>
                    </List>
                    </Grid>
                </Grid>

                
                <Grid container md={3} sm={6} xs={12} alignItems="center" justify="flex-start" direction="column" >
                   <Grid item className={classes.FD_footer_column1}>
                   <Typography className={classes.FD_footer_title} >
                    DISCOVER
                    </Typography>
                    <Grid item >
                        <img src={facebookIcon} alt="" className={classes.FD_social_img_tag_fb} />
                        <TwitterIcon className={classes.social_img_tag_twitter} />
                        <YouTubeIcon className={classes.social_img_tag_twitter} />
                    </Grid>
                   </Grid>
                   

                </Grid>
               

            </Grid>
        </Grid>
      

    </Grid>
    );
}

export default Footer;