import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import './style.css'

export default function InputAdornments (props) {
  const useStyles = makeStyles(theme => ({
    InputDiv: {
      alignSelf: 'center',
      width: props.width,
      display: 'flex',
      border:  props.customBorder || '1px solid #C8C8C8',
      height: 44,
      borderRadius: 4,
    },
    dollorIcon: {
      width: '20px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '20px',
      fontFamily: 'Roboto-Regular',
      marginLeft:5,
      marginTop:2,
      color:'#294C5C',
      position: "absolute"
    },
    inputAmount: {
      width: '100%',
      height: '37px',
      // textIndent: '10px',
      border: 0,
      fontSize: 20,
      fontFamily: 'Roboto-Regular',
      fontWeight: 500,
      borderBottom: '1px solid #C8C8C8',
      marginLeft:2,
      marginTop:10,
      '&:focus': {
       outline:'none'
      }
    },
    etext: {
      display: 'flex',
      fontFamily: 'Roboto-Regular',
      letterSpacing: 0.4,
      color: 'red',
      fontSize:11,
      paddingTop: .80,
      marginLeft: 6,
      textAlign: 'left',
      width: '100%'
    }
  }))

  const classes = useStyles()
  return (
    <div className="main">
      <div className={classes.InputDiv}>
        {
          props.countryCode
            ? <div style={{ marginRight: '5px', marginTop: '-8px', padding: '0px' }}>{props.countryCode}</div>
            : <div className={classes.dollorIcon}>{props.leftText}</div>
        }
        <input
          style={props.style}
          className={classes.inputAmount}
          // type={props.type || 'text'}
          inputmode="numeric"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          maxlength={props.maxlength}
        />
      </div>
      <span className={classes.etext}>{props.errorText}</span>
    </div>
  )
}
