export function updateSteps (value) {
  return dispatch => dispatch({ type: 'SET_STEPS', payload: value })
}

export function editFeat (obj) {
  return dispatch => dispatch({ type: 'SET_EDIT', payload: obj })
}

export function updateSideBarFlag (value) {
  return dispatch => dispatch({ type: 'SET_SIDEBAR', payload: value })
}
