import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

export default function IconLabelButtons (props) {
  const customColor= props.customColor;
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      width: props.widthHeight[0] + 'px',
      height: props.widthHeight[1] + 'px',
      color: '#fff',
      borderRadius: 7,
      border: props.customBorder ? props.customBorder : 'none',
      backgroundColor: customColor ? customColor : '#05CE78',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    text: {
      fontFamily: 'Roboto-Bold',
      fontSize: '16px',
      letterSpacing: '.88px',
      paddingLeft:10,
    },
    outlineBtn: {
      margin: theme.spacing(1),
      width: props.widthHeight[0] + 'px',
      height: props.widthHeight[1] + 'px',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      color: '#818E94',
      border: props.customBorder,
      '&:hover': {
        background: customColor ? customColor : '#05CE78',
        color: '#fff'
      },
      '&:focus': {
        background: customColor ? customColor : '#05CE78',
        color:'white'
      }
    },
    iconBtnTitle:{
      fontSize:20,
      paddingLeft:10,
      '&:hover': {
        color: '#fff'
      }
    },
    btnTitle:{
      '&:hover': {
        color: '#fff'
      }
    },
    txt:{
      marginTop:2
    }
  }))

  const classes = useStyles()
  return (
    <>
      {props.variant !== 'outlined' ? (
        <Button
          style={props.style}
          disabled={props.disabled}
          onClick={ props.onClick} className={classes.button}>
          {props.icon ? props.icon : ''}{' '}
          <span className={classes.text}>{props.title}</span>
        </Button>
      ) : (
        <Button
          onClick={props.onClick}
          variant='outlined'
          color='#000'
          className={classes.outlineBtn}
          style={props.style}
        >
          {props.icon?<p className={classes.txt}>
            {props.icon ? props.icon : ''}{' '}
            <span style={props.style} className={classes.iconBtnTitle}>{props.title}</span>
          </p> : <span style={props.style} className={classes.btnTitle}>{props.title}</span>}

        </Button>
      )}
    </>
  )
}

// <Button
// title={"Login To Deenconnect"}
// widthHeight={[180, 50]}
// // onClick={functionCall}
// variant="outlined"
// icon={<ArrowBackIcon />}
// />
