import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './style.css'
export default function Checkboxes(props) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    props.onClick()
  };

  return (
    <div style={{ position: 'absolute', margin: '-3px 0 0 -8px'}}>
      <Checkbox
        checked={props.checked}
        color="primary"
        onChange={handleChange}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
}
