import React, { Component } from 'react'
import Notification from '../Notification'

import Grid from '@material-ui/core/Grid'
import LockIcon from '@material-ui/icons/Lock'
import { Button, Input } from '../../components'
import { connect } from 'react-redux'
import ReactCodeInput from 'react-verification-code-input'
import swal from 'sweetalert2'
import { verification, generateOtp, phoneVerification } from './api'
import { updateUserAsGuest } from '../../store/users/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style.css'

class Verification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isCode: this.props.isCode,
      title: '',
      code: [],
      convertCode: '',
      invalidCode: false,
      errorMsg: '',
      verified: false,
      verifyDisable: true,
      width: '',
      loading: false
    }

    this.handleCode = this.handleCode.bind(this)
    this.isUserCheck = this.isUserCheck.bind(this)
    this.callGenerateOtpApi = this.callGenerateOtpApi.bind(this)
    this.phoneConfirm = this.phoneConfirm.bind(this)
    this.confirm = this.confirm.bind(this)
  }

  handleCode () {
    this.setState({
      isCode: true,
      title: 'Enter Verification Code'
    })
  }

  componentDidMount () {
    this.setState({ title: this.props.eptype.title })
    window.addEventListener('resize', this.resize.bind(this))
    this.resize()
  }

  resize () {
    const currentWidth = (window.innerWidth <= 599)
    this.setState({ width: currentWidth })
  }

  isUserCheck (verifyObj) {
    const { user, email, phoneNumber } = this.props
    if (email) {
      if (user.email === email) {
        this.handleCode()
      } else {
        window.alert('Email Not email')
      }
    }
    if (phoneNumber) {
      this.callGenerateOtpApi()
    } else {
      this.setState({
        errorMsg: 'Please enter phone number'
      })
    }
  }

  async callGenerateOtpApi () {
    try {
      const { phoneNumber, updateUserAsGuest } = this.props
      const numberRegex = /^[+0-9]*$/
      if (numberRegex.test(phoneNumber)) {
        this.setState({ loading: true })
        const otpRes = await generateOtp({ phoneNo: phoneNumber })
        this.setState({ loading: false })
        this.props.showAlert('success')
        if (otpRes.data.success) {
          updateUserAsGuest(otpRes.data, phoneNumber)
          this.handleCode()
        }
      } else {
        this.setState({
          errorMsg: 'Only numbers are allowed'
        })
      }
    } catch (error) {
    }
  }

  async confirm () {
    const { code } = this.state
    const { userName, phoneNumber } = this.props
    if (phoneNumber) {
      this.phoneConfirm()
    } else {
      const verifyBody = {
        userName: userName,
        code: code
      }

      const verificationRes = await verification(verifyBody)
      if (verificationRes.success) {
        this.setState({ verified: true })
        this.props.propsForNav.forNextStep()
      }
    }
  }

  async phoneConfirm () {
    const { code } = this.state
    const { phoneNumber } = this.props
    const verifyBody = {
      phoneNo: phoneNumber,
      otp: code
    }
    this.setState({ loading: true })
    const verificationRes = await phoneVerification(verifyBody)
    this.setState({ loading: false })
    if (verificationRes.data === 'OTP Verified') {
      this.props.propsForNav.forNextStep('otp', verificationRes.data)
      this.setState({ verified: true })
    } else {
      this.setState({ invalidCode: true })
    }
  }

  render () {
    const { isCode, title, code, errorMsg, verified, width, verifyDisable, loading } = this.state
    const { eptype, num, phoneNumber } = this.props
    const number = num.replace(/-/g, ' ')

    return (
      <>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        > */}
        {/* <div class='right_col_title' style={{ width: '100%' }}>
            <p />
            {title}
            <br />
          </div> */}
        {!verified && !isCode && !this.props.newAccount && (
          <div className='notCode'>
            <div style={{ width: '100%' }}>
              <snap className='verifyPhone-text2'>{eptype.description} </snap>
            </div>
            <br />
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 0 }}>
                <div>
                  {this.props.inputField}
                  {!phoneNumber && <p style={{
                    color: 'red',
                    marginTop: 0,
                    fontSize: 12,
                    marginLeft: 5
                  }}
                  >
                    {errorMsg}
                  </p>}
                </div>
              </Grid>
              {/* <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginLeft: 20 }} className='send_code'>

                {!loading ? <Button
                  disabled={!phoneNumber}
                  title='Send Code'
                  widthHeight={!width ? [260, 50] : [450, 50]}
                  icon={<i class='fas fa-paper-plane' />}
                  onClick={() => this.isUserCheck()}
                /> : <div style={{ textAlign: 'center' }}><CircularProgress color="primary" /></div>}

              </Grid> */}
            </Grid>

          </div>
        )}
        {/* </div> */}

        {!verified && isCode && (
          <>
            {!this.props.newAccount === true ? (
              <div className='code'>

                <p className='verifyPhone-text'>
                  Please enter the verification code sent to your{' '}
                  {eptype.type === 'email' ? 'email address' : 'phone number'}
                  <br />
                  {/* <a href='#'>{data ? this.props.phoneNumber : eptype.userContact}</a> */}
                  <a className='phoneNumber'>{number + phoneNumber[phoneNumber.length - 1]}</a>
                  <a
                    style={{ cursor: 'pointer' }}
                    className='changeNumber'
                    onClick={() => this.setState({ isCode: false })}
                  >Change number
                  </a>
                </p>
              </div>

            ) : (
              <center>
                <p style={{ color: '#B8C0C3', lineHeight: '25px', width: '496px' }}>
                    Please enter the verification code sent to your email address
                  <br /> <a href='#'>{eptype.email}</a>
                </p>
              </center>
            )}

            <Grid container className='code'>
              <Grid item lg={8} md={8} sm={12} xs={12}>

                <div className={this.state.invalidCode ? 'enter_numberInvalid' : 'enter_number'}>
                  <ReactCodeInput
                    fields={6}
                    onComplete={() => this.setState({ verifyDisable: false })}
                    className='form-codeInput'
                    type='number'
                    onChange={value => this.setState({ ...code, code: value })}
                  />

                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div style={{ marginTop: 25 }} className='verify'>

                  {!loading ? <Button
                    disable={verifyDisable}
                    title='Verify'
                    widthHeight={!width ? [170, 50] : [450, 50]}
                    onClick={() => this.confirm()}
                    style={{ marginTop: 20 }}
                  /> :
                  <div style={{ textAlign: 'center' }}><CircularProgress color="primary" /></div>}
                </div>
              </Grid>

            </Grid>
            {this.state.invalidCode && <p style={{ color: 'red' }} className='mrg_top'>Code didn't match. Please try again</p>}
            <div class='join_deen mrg_top'>
              Didn’t receive the code? <a style={{ cursor: 'pointer' }} onClick={() => this.isUserCheck()}>Resend Code</a>
            </div>
            <br />

          </>
        )}
        {verified &&

          <Grid container>
            <Grid item lg={6}>
              <div className='vInput'>

                <Input
                  disabled
                  value={number + phoneNumber[phoneNumber.length - 1]}
                  label='Phone Number'
                  type='text'
                  helperText='Verification is required for all donations'
                />
              </div>
            </Grid>
          </Grid>}
      </>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.users.user,
    userAsGuest: state.users.userAsGuest,
    payment: state.payment
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateUserAsGuest: (payload, phoneNumber) => dispatch(updateUserAsGuest(payload, phoneNumber))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verification)
