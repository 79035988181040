import React from 'react'
import Navigation from './navigations'
import { Provider } from 'react-redux'
import { Loader } from './components'
import store from './store'
import './App.css'

function App () {
  const [loader, setloader] = React.useState(false);
  React.useEffect(()=>{
    setTimeout(() => {
      setloader(true)
    }, 3000);
  })
  return (
    <Provider store={store}>
      <>
      <div className={loader ? 'app-container-show' : 'app-container-hide'}>
      <Navigation />
      </div>
      <div className={loader ? 'app-container-hide' : 'app-container-show'}>
      <Loader />
      </div>
      </>
    </Provider>
  )
}

export default App
