import React from 'react';
import Banner from '../common/banner';
import { Grid,List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    FD_pagePart: {
        background: "#05CE78",
        textAlign: "center",
        height: "52px"
    },
    FD_padding_zero: {
        padding: "0 !important"
    },
    tabsStyles: {
        width:"auto",
        color: "white",
        fontWeight: 500
    },
    singleTab: {
      height: "28px",
      marginTop: "0px",
      minWidth: "auto",
      padding: "0px 24px 0px 24px",
      opacity: 'inherit'
    },
    bigIndicator: {
      height: 5,
      backgroundColor: "white"
    },
    activeTextColor: {
      color: "white",
    //   fontWeight: "bold"
    },
    FD_bodyPart: {
        maxWidth: "1140px",
        margin: "0 auto",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    inner_Body: {
        padding: "24px"
    },
    FD_bodyTitle:{
        color: "#294C5C",
        fontSize: "28px",
        fontWeight: 600,
        margin: "5px 0",
    },
    FD_updatedTime: {
        margin: "5px 0",
        color: "#294C5C",
        opacity: "0.8",
        fontSize: "14px"
    },
    FD_bodysubTitle: {
        color: "#294C5C",
        fontSize: "26px",
        fontWeight: 600,
        margin: "5px 0"
    },
    FD_bodyText: {
        color: "#294C5C",
        fontSize: "14px",
        margin: "14px 0",
        lineHeight: 2,
        letterSpacing: "0.18px"
    },
    FD_linkColor: {
        color: "#05CE78",
        textDecoration: "none"
    }

    }));




const PrivacyPolicy = () =>  {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <Banner title="Terms and Policies" />

            <Grid container  className={classes.FD_pagePart} >
                <Grid container className={classes.FD_padding_zero} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
    
                        <Grid container xs={12} justify="center" >
                        <Tabs
                        className={classes.tabsStyles}
                            value={value}
                            classes={{indicator: classes.bigIndicator}}
                            onChange={handleChange}
                            aria-label="tab"
                            
                            
                        >
                            <Tab className={classes.singleTab+" "+(value === 0 ? classes.activeTextColor : '' )} label="TERMS OF SERVICE" />
                            <Tab className={classes.singleTab+" "+(value === 1 ? classes.activeTextColor : '' )} label="PRIVACY POLICY" />
                        </Tabs>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid container  className={classes.FD_bodyPart} >

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                PRIVACY POLICY
                            </Typography>
                            <Typography className={classes.FD_updatedTime} variant="body1" component="p">
                                Last updated: Apr 21, 2020
                            </Typography>
                            <Typography className={classes.FD_bodysubTitle} variant="h1" component="h1">
                                WHO IS DEENFUND?
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            DEENFUND (hereinafter, referred to as "DEENFUND", "we" or "us") an online donation platform for Not-for-profit Organizations and has the rights to use the data from DEENFUND for other product lines and marketing purpose.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Official Company Name:</b>DEENFUND Inc.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Postal Address:</b> 81 Prospect St. Brooklyn, NY 11201
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Email address:</b> &nbsp;    <a href="privacy@deenfund.com" className={classes.FD_linkColor}>privacy@deenfund.com</a>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                When using the DEENFUND application, we can use your personal data in different ways:
                            </Typography>

                            <li className={classes.FD_bodyText} >
                                We can act as &nbsp; <b>data controllers</b>&nbsp; of the personal information you provide us when you register with us. This means that we are the ones who decide how to use your personal data. However, this only applies when you provide your data directly to us. This Privacy Policy regulates how we use your personal information in this scenario.
                            </li>
                            <li className={classes.FD_bodyText}>When you use the DEENFUND application as a donor to donate to an organization, it is usually the organization who is the data controller of your personal data, and therefore, who decides how to use your data. In those cases, we only act as data processors, since we will only have access to your personal data on behalf of the organization and only to render our services to them. Consequently, in such cases, the processing of data is subject to the organization’s corresponding privacy policy or terms, which we strongly suggest you read carefully before donating.</li>
                            <li className={classes.FD_bodyText}>We created the DEENFUND application (the “Application”) to make it simple for users to explore and support organizations they care about with their friends and others with similar interests. We give you control of your information because you may not want to share your information with everyone. You choose what information you provide, including contact and personal information, interests, and causes. You control the sharing of that information by using the privacy settings on the Application.</li>
                           
                           
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND places a high priority on protecting your privacy. This privacy policy was created in order to demonstrate the DEENFUND’s firm commitment to the privacy of our members and website users. We created the DEENFUND application to make it simple for users to explore and support organizations they care about with their friends and others with similar interests. We give you control of your information because you may not want to share your information with everyone. You choose what information you provide, including contact and personal information, interests, and causes.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> If you face any trouble please <Link to="#" className={classes.FD_linkColor}>contact us</Link> at <a href="mailto:privacy@deenfund.com" className={classes.FD_linkColor}>privacy@deenfund.com</a>&nbsp;or at 81 Prospect St. Brooklyn, NY 11201. To learn more about our privacy practices, read on!</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
            <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                            WHAT INFORMATION DO WE USE ABOUT YOU?
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            The project users are global user from anywhere and any organization/any donor from anywhere in the world. While using the application, you may provide us with anonymous information, personal information and additional materials.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            <b>Use of&nbsp;Cookies:</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            DEENDFUND uses "cookie" messages to automatically help provide better services. They remind us who you are and your preferences for our website based on what you've done and told us before. The "cookie" is placed in your computer and is read when you come back to our website. Cookies let us take you to the information and features you're particularly interested in. They also let us track your usage of DEENDFUND, so we know which parts of our sites are most popular. You can reject cookies or cancel them by instructing your Web browser accordingly. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                You provide us and we may collect anonymous information such as your IP address, areas of the Application you have visited, time and date, and whether or not you clicked on links. We use technologies such as cookies to collect this information. A cookie is a piece of data stored on a user's computer. We use session ID cookies to confirm that users are logged in. Session cookies terminate once the user closes the browser. We also use persistent cookies that store other information to make it easier for you to login when you come back to the Application. You can configure your browser to block cookies; however, if you do, the Application may not function properly or at all. If you choose to click on a link to any outside website, anonymous information may be provided to that website.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            We use Google Analytics to help analyze how users use the Site. Google Analytics uses Cookies to collect information such as how often users visit the Site, what pages they visit, and what other sites they used prior to coming to the Site. We use the information we get from Google Analytics only to improve our Site and Services. Google Analytics collects only the IP address assigned to you on the date you visit the Site, rather than your name or other personally identifying information. We do not combine the information generated through the use of Google Analytics with your personal information. Although Google Analytics plants a persistent Cookie on your web browser to identify you as a unique user the next time you visit the Site, the Cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google Analytics about your visits to the Site is restricted by the 
                            </Typography>
                           <Typography className={classes.FD_bodyText} variant="body1" component="p">
                           You may provide us personal information if you register with DEENFUND, like your username, password, first and last names, email address, and mailing address. You may provide additional personal information for your profile. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                            However, we will never ask you to provide us with any confidential or sensitive data, and you should never do so. Sensitive data means any information related to racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data, data concerning health or data concerning a natural person's sex life or sexual orientation or data concerning criminal records. If we learn that you have provided us with any such information, we will disregard it and delete it immediately, and we will not include it in our database.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                HOW DO WE USE YOUR DATA?
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                Depending on how you interact with us, we will use your data for different purposes, as follows:
                            </Typography>
                            <li className={classes.FD_bodyText}> <b>To handle your registration with DEENFUND&nbsp;</b>- If you decide to register with us and create a user account, we will use your data to handle your registration. This includes that we may use your data to provide better products and/or services to you and for administration, maintenance and development of the Application.</li>
                            <li className={classes.FD_bodyText}> <b>To respond to your questions and claims</b>&nbsp;- Again, if you get in touch with us to ask any questions or to raise a claim, we will use your personal information to respond to your questions or to handle your claims, in order for us to provide a better client service.</li>
                            <li className={classes.FD_bodyText}> <b>To handle your donations with organizations</b>&nbsp;- If you use our application to donate to an organization, we will use your personal data to handle your donation and make sure it reaches the organization. However, as mentioned above, in this case, your data will be controlled by the organization, so we suggest that you carefully read their privacy policies or terms. In this context, following the organization’s instructions, we may also use anonymous information collected to improve the quality of the Application. This information may help us to diagnose problems, maintain security, and provide services users want. DEENFUND may determine user demographics, interests, and behavior and may share such information with others. For example, we may provide demographic or usage information to an organization so they may see how many users are from a specific geographic area or industry. However, please don’t worry. This information will always be anonymous and will not be enough to identify you.</li>
                            <li className={classes.FD_bodyText}><b>To let you post Materials</b>&nbsp;directly to public portions of the Application such as causes, forums, communities, or message boards, in which case obviously those Materials may be available to other users. Add Organization - Organizations will go through a verification process by DEENFUND Inc. to make sure it’s legitimate, and we have the rights to deny an organization if all requirements not met.</li>
                            <li className={classes.FD_bodyText}>There is option of choosing how much you want to donate. Primarily, there are 40, 75 and 100$. However, you can add a custom amount of your own. The donation can be made as one time or you can subscribe to donate on monthly basis. You can login to edit your recurring donation at any time.</li>
                            <li className={classes.FD_bodyText}>You can log in to donate, after putting the respective login details. The donation can be made by choosing the specific organization.</li>
                           </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                DO WE SHARE YOUR DATA WITH THIRD PARTIES?
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                Your personally identifiable information will not be sold to advertisers or spammers. We share your personally identifiable information in three cases: (1) with your permission, (2) as required by law, and (3) change of ownership, each as more fully described in the paragraphs below. We may be required to disclose user information pursuant to lawful requests, such as subpoenas or court orders, or in compliance with applicable laws. We do not reveal information until we have a good faith belief that an information request by law enforcement or private litigants meets applicable legal standards. Additionally, we may share account or other information when we believe it is necessary to comply with law, to protect our interests or property, to prevent fraud or other illegal activity perpetrated through the DEENFUND service or using the DEENFUND name, or to prevent imminent bodily harm. This may include sharing information with other companies, lawyers, agents or government agencies. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                If the ownership of all or substantially all of DEENFUND's business, or substantial portions of the business owned by DEENFUND, were to change, your information may be transferred to the new owner so the new owner can continue providing services to you. In any such case, your information would remain subject to the protections provided in our Privacy Policy. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                Please remember that anyone who has access to your username and password may be able to access your personal information; therefore, we encourage you to keep your password secret to prevent any unwanted access. 
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                In addition, please remember that you are in control of sharing personal information that you provide to the Application, including by using the privacy settings.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>IP addresses</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND &nbsp; uses your IP address to help diagnose problems with our server, 
                            </Typography>
                            <Typography className={classes.FD_bodyText}>to&nbsp;<Link to="#" className={classes.FD_linkColor}>doel.deenfund.com</Link>&nbsp;administer, and for statistical metrics used to track website visitor traffic.</Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p"> 
                                <b>How Your Information May Be Used </b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                We use your personal information to provide you with personalized service; to send e-mail alerts to you; to answer your requests; to process your membership application; etc. You may choose to opt out at any time, which will cease all communications from us. We may also use your information to track visitor of our website. This lets us see which of our features are the most popular so we can better serve our users' needs. It also lets us provide aggregate data about our traffic (not identifying you personally, but showing how many visitors used which features, for example) to outside parties.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Contact Form</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND &nbsp; is the sole owner of the information collected on this site. We only have access to/collect information that you voluntarily give us via form submissions, email or other direct contact from you. We will use your information to respond to you regarding the reason you contacted us or to exercise due process on child/family applications. The website asks for contact information such as name, address, phone number, email, etc. when online forms are used. We may use this information you give to provide you with a receipt, to verify or obtain additional information about a donation or gift you have made.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Email Privacy&nbsp;</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                             The DEENFUND &nbsp; does not provide, sell, or rent email addresses to anyone outside the organization. 
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.inner_Body} >
                    <Grid container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >
                        <Grid container xs={12} direction="column" >
                            <Typography className={classes.FD_bodyTitle} variant="h1" component="h1">
                                WHAT ARE YOUR RIGHTS WHEN WE USE YOUR DATA?
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Child Protection</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                DEENFUND does not offer the Application to, or knowingly collect or solicit information from, anyone under the age of 13. If you are under 13, do not provide us with any information. Any information we receive from people we believe to be under 13 will be purged from our database.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            <b>Links to Other Websites</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                The Application may contain links to other websites, including of nonprofits, PACs, and other organizations. DEENFUND is not responsible for the privacy practices or the content of other websites that may be linked to the Application. For more information about how any such third parties use your data, please read their corresponding privacy policies or terms carefully.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                             <b>Agreement</b>
                            </Typography>
                           
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                If you choose to use the Application, your use is subject to the current Terms of Use and Privacy Policy. We reserve the right to change this Privacy Policy from time to time. If we do make changes, we will do our best to notify you of the changes, such as by posting any changes on the Application and indicating the date the changes were made at the top of our Privacy Policy. Non-material changes are effective upon posting. Material changes are effective 15 days after posting. Unless stated otherwise, our current Privacy Policy applies to all information in our possession.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                                <b>Security</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            DEENFUND takes reasonable precautions to protect our users' information. Your account information is located on a secured server behind a firewall. Because email and instant messaging are not recognized as secure communications, we request that you not send private information to us by email or instant messaging services. Although we allow you to set privacy setting that limit access to your information, please be aware that no security measures are perfect or 100% effective. We cannot control the actions of other users with whom you may choose to share your pages and information. Even after removal, copies of Materials may remain viewable in cached and archived pages or if other users have copied or stored your Materials. If you have any questions about the security of DEENFUND Application, please contact us at privacy@deenfund.com.
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            <b>Modifications&nbsp;</b>
                            </Typography>
                            <Typography className={classes.FD_bodyText} variant="body1" component="p">
                            We may amend this privacy policy from time to time; please review it periodically. We maintain the option to modify this privacy at any time by electronic notice posted on our website. Your continued use of our website after the date that such notices are posted will be deemed to be your agreement to the changed terms. 
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

        </React.Fragment>
            
    );
}

export default PrivacyPolicy;