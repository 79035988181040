import React, { Component } from 'react'
import { Button, Input, Verification } from '../../components'
import LockIcon from '@material-ui/icons/Lock'
import Recaptcha from 'react-recaptcha'
import { signUp } from '../../components/Donation/apis'
import Grid from '@material-ui/core/Grid'

export default class Signup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullName: '',
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      isVerification: false,
      recaptchaFlag: false,
      error: false
    }

    this.handlesignUP = this.handlesignUP.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
    this.emailCheck = this.emailCheck.bind(this)
  }

  emailCheck (field, value) {
    const { email, password } = this.state
    if (field === 'email') {
      const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (emailRegex.test(email)) {
        this.setState({ email: value, emailError: '' })
      } else {
        this.setState({ emailError: 'Email Formate Invalid' })
      }
      this.setState({ email: value })
    }
    if (field === 'password') {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
      if (passwordRegex.test(password)) {
        this.setState({ passwordError: '' })
      } else {
        this.setState({
          passwordError: ' use letter digit special characters'
        })
      }
      this.setState({ password: value })
    }
  }

  recaptchaLoaded () {
  }

  verifyCallback (response) {
    if (response) {
      this.setState({ recaptchaFlag: true })
    }
  }

  async handlesignUP () {
    const { fullName, email, password, recaptchaFlag } = this.state
    if (recaptchaFlag) {
      if (fullName && email && password) {
        const user = {
          userName: email,
          password: password,
          fullName: fullName
        }
        const getRes = await signUp(user)
        if (getRes.data.success) {
          this.setState({
            isVerification: true
          })
        }
      } else {
        this.setState({ error: true })
      }
    } else {
      this.setState({ error: true })
    }
  }

  render () {
    const {
      fullName,
      email,
      password,
      emailError,
      passwordError,
      isVerification
    } = this.state

    const eptype = {
      type: 'email',
      title: 'Enter Verification Code',
      email: email,
      description:
        'don`t wory, it happen to the best of us. just enter the email address associated with your acount tp rest password'
    }
    return (
      <>
        {!isVerification && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <>
                {/* <div className='right_col_title'>Sign Up To Donate</div> */}
                <div style={{ width: '100%', alignSelf: 'center' }}>

                  <Input
                    label='Full Name'
                    type='text'
                    value={fullName}
                    helperText='Enter User Name'
                    onChange={(e) => {
                      this.setState({ fullName: e.target.value })
                    }}
                    style={{ border: '1px solid red', borderRadius: 5 }}
                  />

                  <Input
                    label='Full Name'
                    type='text'
                    value={fullName}
                    helperText='Enter User Name'
                    onChange={(e) => {
                      this.setState({ fullName: e.target.value })
                    }}
                    style={{ border: '1px solid red', borderRadius: 5 }}
                  />

                  <Input
                    value={email}
                    label='Email or Phone'
                    type='email'
                    helperText={emailError}
                    onChange={(e) => {
                      this.emailCheck('email', e.target.value)
                    }}
                    style={{ border: '1px solid red', borderRadius: 5 }}
                  />

                  <Input
                    label=' Enter Password'
                    type='password'
                    helperText={passwordError}
                    value={password}
                    onChange={(e) => {
                      this.emailCheck('password', e.target.value)
                    }}
                    style={{ border: '1px solid red', borderRadius: 5 }}
                  />

                  <div style={{ margin: '10px 0 0 8px', width: '100%' }}>
                    <Recaptcha
                      sitekey='6LfcbugUAAAAAOybXXgV8Ze7Jt5VCgzJH7-49z9-'
                      render='explicit'
                      onloadCallback={() => this.recaptchaLoaded()}
                      verifyCallback={(response) => this.verifyCallback(response)}
                    />
                  </div>

                  {this.state.error && <p style={{ color: 'red' }}>Please verify that you are human</p>}

                  <p
                    style={{
                      color: '#818E94',
                      lineHeight: '20px',
                      marginLeft: '8px'
                    }}
                  >
                    By sign up, you confirm that you've accepted our{' '}
                    <b>Terms & Conditions</b> and <b>Privacy policy</b>
                  </p>

                  {/* <Button
                    title='SIGN UP TO DEENFUND'
                    widthHeight={[510, 60]}
                    icon={<LockIcon />}
                    onClick={() => {
                      this.handlesignUP()
                    }}
                  /> */}

                  <center>
                    <p
                      style={{
                        color: '#818E94',
                        lineHeight: '20px',
                        fontFamily: 'Roboto-Regular'
                      }}
                    >
                      {' '}
                      Already have an account?{' '}
                      <span
                        onClick={() => this.props.onClick()}
                        style={{ color: '#05CE78', cursor: 'pointer', fontFamily: 'Roboto-Medium' }}
                      >
                        LOGIN TO DEENFUND
                      </span>
                    </p>
                  </center>
                </div>
              </>
            </div>
          </>
        )}
        {isVerification && (
          <center>
            <Verification
              userName={email}
              eptype={eptype}
              newAccount={`${true}`}
              isCode={`${true}`}
              onPressNextStep={this.props.onNextstep}
            />
          </center>
        )}
      </>
    )
  }
}
