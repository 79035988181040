import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import './style.css'

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowHeader: true
    }
  }
  render() {
    const { org, orgData } = this.props;

    return (
      <>

          <div className='embd_footer'>
            <div>
              {org && <div className='embd_ftr_top br_on_mobile'>
                <h1>{orgData?.orgName}</h1>
                {org?.embedForm?.[0]?.setup?.footer?.taxNumber === 'true' && orgData?.taxIdStatus?.taxIdEin &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p style={{ marginBottom: 5, marginRight: 5 }}>We are 501(c)(3) tax-exempted organization. Tax ID {orgData?.taxIdStatus?.taxIdEin}
                      <Tooltip title="Verified with IRS online portal and eligible to receive tax-deductible charitable contributions" placement='top-start'>
                        <img src={require('../../assets/img/check_circle-24px.svg')} style={{ marginTop: -4 }} className="verified" />
                      </Tooltip>
                    </p>
                  </div>}
                {/* <p>{org.address.line1}, {org.address.line2 && org.address.line2 + ','} {org.address.city}, {org.address.state} {org.address.postal_code}</p> */}
                <p>{org?.embedForm?.[0]?.setup?.footer?.fullOrgAddress}</p>
                <p>{org?.embedForm?.[0]?.setup?.footer?.phoneNumber && 'Phone : ' + org?.embedForm?.[0]?.setup?.footer?.phoneNumber }  <span className="footer_email">{org?.embedForm?.[0]?.setup?.footer?.email && "Email : " + org?.embedForm?.[0]?.setup?.footer?.email }</span></p>
              </div>
              }

              <div className="embd_ftr_border"></div>

              <div className='embd_btm_ftr' style={{backgroundColor: '#314866', paddingBottom: '10px' }}>

                {/* <Grid container>
              <Grid item lg={4} md={4} sm={12}> */}

                <div className='btm_ftr_left'>
                  <p>
                    <a href='https://deenfund.com/terms-policies' target="_blank">Terms of Service</a>
                    <span> | </span>
                    <a href='https://deenfund.com/privacy-policy' target="_blank">Privacy Policy</a>
                  </p>

                  <p>Copyright © 2021 <span className="symbol"></span> All Right Reserved</p>
                </div>

                {/* </Grid> */}

                {/* <Grid item lg={8} md={8} sm={12}> */}
                <div className='btm_ftr_right'>

                  <div className='copyright_links'>
                    <p>POWERED BY</p> <img src={require('../../assets/img/deenfund_logo_white.png')} />
                  </div>
                </div>
                {/*
              </Grid>
            </Grid> */}
              </div>

            </div>
          </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    org: state.org.org,
    orgData: state.org.orgData,
  }
}

export default connect(mapStateToProps, {})(Footer)
