import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import userReducer from './users/reducers'
import amountReducer from './payment/reducers'
import stepsReducer from './Steps/reducers'
import orgReducer from './org/reducers'

const rootReducer = combineReducers({
  users: userReducer,
  payment: amountReducer,
  steps: stepsReducer,
  org: orgReducer
})

const store = createStore(rootReducer, {}, applyMiddleware(thunk))

export default store
