import axios from 'axios'

const baserUrl = 'https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000'
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
}

const sharedEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
}
let currentEnv
if (window.location.host.includes('amazon') || window.location.host.includes('localhost') || window.location.host.includes('dev')) {
  currentEnv = 'dev'
} else {
  currentEnv = 'prod'
}
const paymentBaseUrl = env[currentEnv]
const orgBaseUrl = sharedEnv[currentEnv]

export function signUp(user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baserUrl}/auth/register`, user)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function login(user) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baserUrl}/auth/authenticate`, user)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function stripTokenGet(cardInfo, chargeInfo) {
  console.log("chargeInfo", cardInfo, chargeInfo)
  return new Promise((resolve, reject) => {
    axios
      .post(`${paymentBaseUrl}/stripe/token`, cardInfo)
      .then((res) => {
        if (res.data.body.data) {
          // console.log("res/stripe/token", res)
          const { amount, orgId, phoneNo, description, frequency, categoryId,
            processingFee, memoNote, firstName, lastName, email, billing_details } = chargeInfo
          axios
            .post(`${paymentBaseUrl}/stripe/charges`, {
              amount: amount * 100,
              currency: 'USD',
              description,
              // source: '1',
              source: res.data.body.data,
              orgId,
              phoneNo,
              frequency,
              stream: 'DEENFUND',
              categoryId,
              processingFee,
              memoNote,
              firstName,
              lastName,
              email,
              billing_details
            })
            .then((res) => {
              console.log("res/stripe/charges", res)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(res)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getSelectedDonationCategories(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${orgId}&application=deenfund`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getCategories(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${orgId}`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function organizations(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${paymentBaseUrl}/organizations/${orgId}`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getOrgId(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/getOrganizationIdByWebsite?websiteName=${url}`)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function updateStatus(id, status) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${paymentBaseUrl}/stripe/updateInviteDonorStatus/${id}`, {status: status})
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

